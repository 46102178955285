import { useState, useMemo } from 'react';
import { motion } from 'framer-motion';
import { TopicList } from '@/components/TopicList';
import { CreateTopicDialog } from '@/components/CreateTopicDialog';
import { useForumTopics } from '@/hooks/use-forum';
import { TopicCategory, CATEGORY_LABELS } from '@/types/forum';
import { Tabs, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { useSession } from '@supabase/auth-helpers-react';
import { Input } from '@/components/ui/input';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import { Button } from '@/components/ui/button';
import { Search, SlidersHorizontal, MessageSquare } from 'lucide-react';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import { Label } from '@/components/ui/label';
import { Switch } from '@/components/ui/switch';

type SortBy = 'recent' | 'reactions';

// Componente separado para as partículas
function ParticlesEffect() {
  const particles = useMemo(() => 
    [...Array(50)].map(() => ({
      left: `${Math.random() * 100}%`,
      top: `${Math.random() * 100}%`,
      delay: `${Math.random() * 3}s`,
      duration: `${2 + Math.random() * 3}s`
    })), []
  );

  return (
    <div className="fixed inset-0 overflow-hidden pointer-events-none">
      {particles.map((particle, i) => (
        <div
          key={i}
          className="absolute w-1 h-1 bg-blue-400 rounded-full animate-twinkle"
          style={{
            left: particle.left,
            top: particle.top,
            animationDelay: particle.delay,
            animationDuration: particle.duration
          }}
        />
      ))}
    </div>
  );
}

export default function Forum() {
  const session = useSession();
  const [category, setCategory] = useState<TopicCategory | undefined>();
  const [search, setSearch] = useState('');
  const [sortBy, setSortBy] = useState<SortBy>('recent');
  const [showPinned, setShowPinned] = useState(true);
  const { topics, loading, refetch } = useForumTopics(category);

  // Filtrar e ordenar tópicos
  const filteredTopics = topics
    .filter(topic => {
      if (!showPinned && topic.is_pinned) return false;
      if (search) {
        const searchLower = search.toLowerCase();
        return (
          topic.title.toLowerCase().includes(searchLower) ||
          topic.content.toLowerCase().includes(searchLower) ||
          topic.author?.username?.toLowerCase().includes(searchLower) ||
          topic.author?.display_name?.toLowerCase().includes(searchLower)
        );
      }
      return true;
    })
    .sort((a, b) => {
      if (a.is_pinned && !b.is_pinned) return -1;
      if (!a.is_pinned && b.is_pinned) return 1;

      switch (sortBy) {
        case 'reactions':
          return (b.reactions_count || 0) - (a.reactions_count || 0);
        default: // 'recent'
          return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
      }
    });

  return (
    <div className="min-h-screen bg-gradient-to-b from-[#000510] to-[#001233]">
      <ParticlesEffect />

      {/* Conteúdo principal */}
      <div className="relative z-10 container mx-auto px-4 py-8">
        {/* Cabeçalho */}
        <div className="text-center mb-12">
          <motion.div
            initial={{ scale: 0.8, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            className="inline-block"
          >
            <MessageSquare className="w-16 h-16 text-blue-500 mx-auto mb-4" />
          </motion.div>
          <h1 className="text-4xl font-bold text-white mb-2">
            Comunidade
          </h1>
          <p className="text-zinc-400">
            Participe das discussões, tire dúvidas e compartilhe estratégias
          </p>
        </div>

        {/* Botão de criar tópico */}
        {session && (
          <div className="flex justify-center mb-8">
            <CreateTopicDialog onSuccess={refetch} />
          </div>
        )}

        {/* Categorias */}
        <div className="max-w-4xl mx-auto mb-6">
          <Tabs
            value={category || 'all'}
            onValueChange={(value) => setCategory(value === 'all' ? undefined : value as TopicCategory)}
            className="w-full"
          >
            <TabsList className="bg-zinc-900/50 border border-zinc-800 w-full grid grid-cols-7">
              <TabsTrigger value="all" className="data-[state=active]:bg-blue-600">
                Todos
              </TabsTrigger>
              {Object.entries(CATEGORY_LABELS).map(([value, label]) => (
                <TabsTrigger key={value} value={value} className="data-[state=active]:bg-blue-600">
                  {label}
                </TabsTrigger>
              ))}
            </TabsList>
          </Tabs>
        </div>

        {/* Filtros e Busca */}
        <div className="max-w-4xl mx-auto">
          <div className="flex flex-col sm:flex-row gap-4 mb-8 bg-zinc-900/50 border border-zinc-800 rounded-lg p-4">
            <div className="flex-1">
              <div className="relative">
                <Search className="absolute left-3 top-1/2 -translate-y-1/2 w-4 h-4 text-zinc-400" />
                <Input
                  placeholder="Buscar tópicos..."
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  className="pl-9 bg-zinc-900/50 border-zinc-800 text-white placeholder:text-zinc-400"
                />
              </div>
            </div>
            <div className="flex gap-2">
              <Select value={sortBy} onValueChange={(value) => setSortBy(value as SortBy)}>
                <SelectTrigger className="w-[180px] bg-zinc-900/50 border-zinc-800 text-white">
                  <SelectValue placeholder="Ordenar por" />
                </SelectTrigger>
                <SelectContent className="bg-zinc-900/95 border-zinc-800">
                  <SelectItem value="recent" className="text-white focus:bg-blue-600 focus:text-white">
                    Mais Recentes
                  </SelectItem>
                  <SelectItem value="reactions" className="text-white focus:bg-blue-600 focus:text-white">
                    Mais Curtidos
                  </SelectItem>
                </SelectContent>
              </Select>

              <Popover>
                <PopoverTrigger asChild>
                  <Button variant="outline" size="icon" className="bg-zinc-900/50 border-zinc-800 text-white hover:text-white">
                    <SlidersHorizontal className="w-4 h-4" />
                  </Button>
                </PopoverTrigger>
                <PopoverContent className="w-[200px] bg-zinc-900/95 border-zinc-800 p-3">
                  <div className="space-y-3">
                    <Label htmlFor="show-pinned" className="text-white flex items-center gap-2">
                      <span className="flex-1 text-sm">Mostrar Fixados</span>
                      <Switch
                        id="show-pinned"
                        checked={showPinned}
                        onCheckedChange={setShowPinned}
                        className="data-[state=checked]:bg-blue-600"
                      />
                    </Label>
                  </div>
                </PopoverContent>
              </Popover>
            </div>
          </div>

          {/* Lista de Tópicos */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.2 }}
            className="bg-zinc-900/50 border border-zinc-800 rounded-lg p-6"
          >
            <TopicList topics={filteredTopics} loading={loading} />

            {/* Mensagem quando não há tópicos */}
            {!loading && filteredTopics.length === 0 && (
              <div className="text-center py-12">
                <MessageSquare className="w-12 h-12 text-zinc-600 mx-auto mb-4" />
                {search ? (
                  <>
                    <h3 className="text-lg font-medium text-white mb-2">
                      Nenhum tópico encontrado
                    </h3>
                    <p className="text-zinc-400 mb-4">
                      Tente usar termos diferentes na busca
                    </p>
                    <Button
                      variant="outline"
                      onClick={() => setSearch('')}
                      className="bg-zinc-900/50 border-zinc-800"
                    >
                      Limpar Busca
                    </Button>
                  </>
                ) : !session ? (
                  <>
                    <h3 className="text-lg font-medium text-white mb-2">
                      Entre para participar
                    </h3>
                    <p className="text-zinc-400">
                      Faça login para criar tópicos e participar das discussões
                    </p>
                  </>
                ) : (
                  <>
                    <h3 className="text-lg font-medium text-white mb-2">
                      Nenhum tópico ainda
                    </h3>
                    <p className="text-zinc-400 mb-4">
                      Seja o primeiro a criar um tópico nesta categoria!
                    </p>
                    <CreateTopicDialog onSuccess={refetch} />
                  </>
                )}
              </div>
            )}
          </motion.div>
        </div>
      </div>

      {/* Vinheta nas bordas */}
      <div 
        className="absolute inset-0 pointer-events-none"
        style={{
          boxShadow: 'inset 0 0 200px rgba(0,24,69,0.7)'
        }} 
      />
    </div>
  );
} 