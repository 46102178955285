import React, { useState, useEffect, useRef, useCallback } from 'react';
import { motion } from 'framer-motion';
import { Card } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { Loader2, ArrowLeft, Send } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import CheckerBoard from './CheckerBoard';
import { toast } from 'sonner';
import { useGame } from '@/hooks/use-game';
import { useRooms } from '@/hooks/use-rooms';
import { supabase } from '@/integrations/supabase/client';
import maskSensitiveInfo from '@/integrations/supabase/utils';
import { useIsMobile } from '@/hooks/use-mobile';
import { cn } from '@/lib/utils';
import { useSession } from '@supabase/auth-helpers-react';
import { useProfile } from '@/hooks/use-profile';
import { useUserProfile } from '@/hooks/use-user-profile';
import { useGameTimer } from '@/hooks/use-game-timer';
import { gameSounds } from '@/lib/sounds';
import { Input } from "@/components/ui/input";
import { GameChat } from './GameChat';
import { ScrollArea } from "@/components/ui/scroll-area";
import { Avatar, AvatarImage, AvatarFallback } from "@/components/ui/avatar";
import { format } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import { generateGradientAvatar } from '@/lib/generate-avatar';
import { GameHistory } from './GameHistory';
import { PlayerProfileCard } from './PlayerProfileCard';
import { useAnalytics } from '@/hooks/use-analytics';

interface GameRoomProps {
  roomId: string;
  playerName: string;
  isGuest?: boolean;
}

interface ValidMove {
  row: number;
  col: number;
  isCapture?: boolean;
  capturedRow?: number;
  capturedCol?: number;
}

// Adicionar interfaces para mensagens de chat
interface ChatMessage {
  id: string;
  gameId: string;
  userId: string;
  message: string;
  createdAt: string;
}

const GameRoom = ({ roomId, playerName, isGuest = false }: GameRoomProps) => {
  const navigate = useNavigate();
  const { leaveRoom } = useRooms();
  const session = useSession();
  const { profile } = useProfile();
  const {
    game,
    loading,
    error,
    joinGame,
    makeMove,
    isPlayer1,
    isPlayer2,
    canPlay,
    refetch,
    isCurrentPlayerPiece,
    cleanupPlayerPresence,
    canJoinGame
  } = useGame(roomId);

  const isMobile = useIsMobile();
  const [isMoving, setIsMoving] = useState(false);

  const { profile: player1Profile } = useUserProfile(game?.player1_id);
  const { profile: player2Profile } = useUserProfile(game?.player2_id);

  const [player1Avatar, setPlayer1Avatar] = useState<string | null>(null);
  const [player2Avatar, setPlayer2Avatar] = useState<string | null>(null);

  // Mover a função hasTwoPlayers para aqui, antes do seu uso
  const hasTwoPlayers = () => {
    if (!game) return false;
    
    // Verificar jogador 1 (considerando convidados)
    const hasPlayer1 = Boolean(
      game.player1_id || 
      (game.player1_name && game.player1_name !== 'null' && game.player1_name.trim() !== '')
    );
    
    // Verificar jogador 2 (considerando convidados)
    const hasPlayer2 = Boolean(
      game.player2_id || 
      (game.player2_name && game.player2_name !== 'null' && game.player2_name.trim() !== '')
    );
    
    return hasPlayer1 && hasPlayer2;
  };

  const getDisplayName = (playerId: string | null, playerName: string | null) => {
    if (!playerId && !playerName) return 'Aguardando jogador';
    
    // Se for um nome de convidado, retornar direto
    if (playerName && (!playerId || playerId === 'guest')) {
      return playerName;
    }
    
    // Se tiver um perfil, usar as informações do perfil
    const profile = playerId === game?.player1_id ? player1Profile : player2Profile;
    if (profile) {
      return profile.username ? `@${profile.username}` : profile.display_name || playerName;
    }
    
    // Se não tiver perfil, usar o nome fornecido
    return playerName || 'Jogador';
  };

  // Atualizar a verificação de início de jogo
  const gameStarted = hasTwoPlayers();

  // Atualizar a verificação de turno
  const isMyTurn = game ? (
    (isPlayer1 && game.current_player === 1) || 
    (isPlayer2 && game.current_player === 2)
  ) && gameStarted : false;

  // Atualizar a lógica de carregamento de avatar
  const getPlayerAvatar = useCallback(async (playerId: string | null, playerName: string | null) => {
    if (!playerId && !playerName) return null;
    
    // Se for convidado, gerar avatar baseado no nome
    if (!playerId || playerId === 'guest') {
      return generateGradientAvatar(playerName || '?');
    }

    try {
      const { data: profile } = await supabase
        .from('profiles')
        .select('*')
        .eq('id', playerId)
        .single();

      if (profile?.equipped_skin_id) {
        const { data: skin } = await supabase
          .from('avatar_skins')
          .select('image_url')
          .eq('id', profile.equipped_skin_id)
          .single();
        
        if (skin?.image_url) return skin.image_url;
      }

      if (profile?.avatar_url) return profile.avatar_url;
      if (profile?.display_name) return generateGradientAvatar(profile.display_name);
      if (profile?.username) return generateGradientAvatar(profile.username);
      return generateGradientAvatar(playerName || '?');
    } catch (error) {
      console.error('Erro ao carregar avatar:', error);
      return generateGradientAvatar(playerName || '?');
    }
  }, [supabase]);

  // Atualizar useEffect para carregar avatares
  useEffect(() => {
    if (!game) return;

    const loadAvatars = async () => {
      try {
        const avatar1 = await getPlayerAvatar(game.player1_id, game.player1_name);
        const avatar2 = await getPlayerAvatar(game.player2_id, game.player2_name);
        
        setPlayer1Avatar(avatar1);
        setPlayer2Avatar(avatar2);
      } catch (err) {
        console.error('Erro ao carregar avatares:', err);
      }
    };

    loadAvatars();
  }, [game?.player1_id, game?.player2_id, game?.player1_name, game?.player2_name, getPlayerAvatar]);

  const [selectedPiece, setSelectedPiece] = useState<{ row: number; col: number } | null>(null);
  const [validMoves, setValidMoves] = useState<ValidMove[]>([]);
  const [mandatoryPieces, setMandatoryPieces] = useState<Array<{ row: number; col: number }>>([]);
  const [mustContinueCapturing, setMustContinueCapturing] = useState<{
    row: number;
    col: number;
  } | null>(null);

  const { timeLeft, startTimer, stopTimer } = useGameTimer(30); // 30 segundos por jogada
  const [moveHistory, setMoveHistory] = useState<Array<{
    player: number;
    from: string;
    to: string;
    type: string;
  }>>([]);

  // Adicionar estado para controle de visualização do espectador
  const [viewingPlayer, setViewingPlayer] = useState<1 | 2>(1);

  // Adicionar estados para o chat
  const [messages, setMessages] = useState<ChatMessage[]>([]);
  const [newMessage, setNewMessage] = useState('');
  const chatEndRef = useRef<HTMLDivElement>(null);

  // Adicionar estado para armazenar os perfis
  const [profiles, setProfiles] = useState<Record<string, any>>({});

  // Adicionar useEffect para carregar os perfis
  useEffect(() => {
    const loadProfiles = async () => {
      const { data } = await supabase
        .from('profiles')
        .select('id, username, display_name, avatar_url');
      
      if (data) {
        const profileMap = data.reduce((acc, profile) => {
          acc[profile.id] = profile;
          return acc;
        }, {} as Record<string, any>);
        setProfiles(profileMap);
      }
    };

    loadProfiles();
  }, []);

  // Função para enviar mensagem
  const sendMessage = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!session?.user || !game || !newMessage.trim()) return;

    try {
      const { data, error } = await supabase
        .from('chat_messages')
        .insert({
          game_id: game.id,
          user_id: session.user.id,
          message: newMessage.trim()
        })
        .select()
        .single();

      if (error) throw error;
      setNewMessage('');
    } catch (err) {
      console.error('Erro ao enviar mensagem:', err);
      toast.error('Erro ao enviar mensagem');
    }
  };

  // Efeito para carregar e escutar mensagens
  useEffect(() => {
    if (!game?.id) return;

    // Carregar mensagens existentes
    const loadMessages = async () => {
      const { data, error } = await supabase
        .from('chat_messages')
        .select(`
          *,
          profiles:user_id (
            username,
            display_name
          )
        `)
        .eq('game_id', game.id)
        .order('created_at', { ascending: true });

      if (error) {
        console.error('Erro ao carregar mensagens:', maskSensitiveInfo(error));
        return;
      }

      setMessages(data);
      scrollToBottom();
    };

    loadMessages();

    // Usar um único canal para todas as atualizações do jogo
    const channel = supabase
      .channel(`game:${game.id}`)
      .on(
        'postgres_changes',
        {
          event: '*',
          schema: 'public',
          table: 'games',
          filter: `id=eq.${game.id}`
        },
        async (payload) => {
          console.log('Game update received:', payload);
          if (payload.eventType === 'UPDATE') {
            const updatedGame = payload.new as any;
            
            // Verificar se algum jogador saiu
            const player1Left = (game.player1_name && !updatedGame.player1_name) || 
                              (game.player1_name && updatedGame.player1_name === null) ||
                              (game.player1_id && !updatedGame.player1_id) ||
                              (game.player1_id && updatedGame.player1_id === null);
            
            const player2Left = (game.player2_name && !updatedGame.player2_name) ||
                              (game.player2_name && updatedGame.player2_name === null) ||
                              (game.player2_id && !updatedGame.player2_id) ||
                              (game.player2_id && updatedGame.player2_id === null);

            if (player1Left || player2Left) {
              console.log('Jogador saiu da sala');
              toast.info('O adversário saiu da sala. Aguardando novo jogador...');
              
              // Resetar o estado do jogo
              setSelectedPiece(null);
              setValidMoves([]);
              setMandatoryPieces([]);
              setMustContinueCapturing(null);

              // Aumentar o delay do refresh para dar tempo da mensagem ser enviada e exibida
              setTimeout(() => {
                window.location.reload();
              }, 2000); // Aumentado para 2 segundos
            }

            // Atualizar o estado do jogo depois de verificar as mudanças
            await refetch();
          }
        }
      )
      .subscribe((status) => {
        if (status === 'SUBSCRIBED') {
          console.log('Subscribed to game updates:', game.id);
        }
      });

    return () => {
      channel.unsubscribe();
    };
  }, [game?.id, game?.player1_id, game?.player1_name, game?.player2_id, game?.player2_name, refetch]);

  const scrollToBottom = () => {
    chatEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  // Função para registrar movimentos no histórico
  const addToHistory = (
    player: number,
    fromRow: number,
    fromCol: number,
    toRow: number,
    toCol: number,
    type: string
  ) => {
    const from = `${String.fromCharCode(97 + fromCol)}${fromRow + 1}`;
    const to = `${String.fromCharCode(97 + toCol)}${toRow + 1}`;
    
    setMoveHistory(prev => [...prev, {
      player,
      from,
      to,
      type
    }]);
  };

  // Converter o board de Json para number[][]
  const getBoard = (): number[][] => {
    if (!game?.board) return Array(8).fill(0).map(() => Array(8).fill(0));
    
    try {
      if (Array.isArray(game.board)) {
        return game.board as number[][];
      }
      return JSON.parse(game.board as string) as number[][];
    } catch (err) {
      console.error('Erro ao converter tabuleiro:', err);
      return Array(8).fill(0).map(() => Array(8).fill(0));
    }
  };

  const handleLeaveGame = async () => {
    try {
      console.log('Saindo da sala...');
      
      if (!game) return;

      // Se não for convidado, limpar presença e sair da sala
      if (!isGuest) {
        await cleanupPlayerPresence(false);
        await leaveRoom(roomId);
      } else {
        // Se for convidado, chamar apenas o leaveRoom que agora suporta convidados
        await leaveRoom(roomId);
      }

      // Aguardar um pequeno delay antes de navegar
      await new Promise(resolve => setTimeout(resolve, 1000));
      navigate('/');
    } catch (err) {
      console.error('Erro ao sair da sala:', err);
      toast.error('Erro ao sair da sala');
    }
  };

  // Adicionar useEffect para tocar sons de vitória/derrota
  useEffect(() => {
    if (game?.winner_id) {
      const isWinner = game.winner_id === (isPlayer1 ? game.player1_id : game.player2_id);
      gameSounds.play(isWinner ? 'victory' : 'defeat');
    }
  }, [game?.winner_id, isPlayer1, game?.player1_id, game?.player2_id]);

  const handleSquareClick = async (row: number, col: number) => {
    if (!game) return;

    // Verificar se tem dois jogadores antes de qualquer outra verificação
    if (!hasTwoPlayers()) {
      gameSounds.play('error');
      toast.error("Aguardando outro jogador para iniciar a partida!");
      return;
    }

    // Verificar se o jogo está ativo
    if (!canPlay || isMoving) {
      return;
    }

    const isMyTurn = (isPlayer1 && game.current_player === 1) || 
                    (isPlayer2 && game.current_player === 2);

    // Verificar turno antes de qualquer ação
    if (!isMyTurn && !mustContinueCapturing) {
      gameSounds.play('error');
      toast.error("Não é sua vez de jogar!");
      return;
    }

    // Ao selecionar uma peça
    if (isCurrentPlayerPiece(row, col)) {
      // Verificar restrições de captura obrigatória
      if (mustContinueCapturing && (row !== mustContinueCapturing.row || col !== mustContinueCapturing.col)) {
        gameSounds.play('error');
        toast.error("Você deve continuar capturando com a mesma peça!");
        return;
      }

      // Verificar capturas obrigatórias disponíveis
      if (!mustContinueCapturing) {
        const mandatoryPieces = findMandatoryPieces(getBoard());
        if (mandatoryPieces.length > 0 && !mandatoryPieces.some(p => p.row === row && p.col === col)) {
          gameSounds.play('error');
          setMandatoryPieces(mandatoryPieces);
          toast.error("Você tem capturas obrigatórias disponíveis!");
          return;
        }
      }

      // Seleção válida de peça
      setMandatoryPieces([]);
      setSelectedPiece({ row, col });
      setValidMoves(calculateValidMoves(row, col, !!mustContinueCapturing));
      return;
    }

    // Ao fazer um movimento
    if (selectedPiece) {
      const validMove = validMoves.find(move => move.row === row && move.col === col);
      
      if (validMove) {
        try {
          setIsMoving(true);
          const board = getBoard();
          const selectedPieceType = board[selectedPiece.row][selectedPiece.col];
          const isAlreadyKing = selectedPieceType === 3 || selectedPieceType === 4;
          
          const isPromotion = 
            !isAlreadyKing &&
            ((game.current_player === 1 && row === 7) || 
             (game.current_player === 2 && row === 0));

          if (validMove.isCapture) {
            await makeMove(
              selectedPiece.row,
              selectedPiece.col,
              row,
              col,
              true,
              isPromotion,
              validMove.capturedRow,
              validMove.capturedCol,
              false
            );
            
            gameSounds.play('capture');
            
            // Rastrear captura
            analytics.trackCapture(
              game.id,
              moveHistory.length + 1,
              isAlreadyKing ? 'dama' : 'normal'
            );

            const hasMoreCaptures = calculateValidMoves(row, col, true)
              .some(move => move.isCapture);

            if (hasMoreCaptures) {
              setMustContinueCapturing({ row, col });
              toast.info("Você deve continuar capturando!");
              return;
            }
          } else {
            await makeMove(
              selectedPiece.row,
              selectedPiece.col,
              row,
              col,
              false,
              isPromotion
            );
            
            gameSounds.play('move');
          }

          if (isPromotion) {
            setTimeout(() => {
              gameSounds.play('promotion');
              // Rastrear promoção
              analytics.trackPromotion(game.id, moveHistory.length + 1);
            }, 300);
          }

          // Rastrear movimento normal
          analytics.trackMove(game.id, moveHistory.length + 1);
        } catch (error) {
          console.error('Erro ao fazer movimento:', maskSensitiveInfo(error));
          toast.error('Erro ao fazer movimento');
          // Rastrear erro
          analytics.trackError(
            game.id,
            'movimento_invalido',
            'Erro ao fazer movimento'
          );
        } finally {
          setIsMoving(false);
          setSelectedPiece(null);
          setValidMoves([]);
          setMustContinueCapturing(null);
        }
      }
    }
  };

  const calculateValidMoves = (row: number, col: number, onlyCaptures: boolean = false): ValidMove[] => {
    if (!game) return [];
    
    const board = getBoard();
    const moves: ValidMove[] = [];
    const piece = board[row][col];
    const isKing = piece === 3 || piece === 4;
    const player = piece === 1 || piece === 3 ? 1 : 2;

    const isValidPosition = (r: number, c: number) => r >= 0 && r < 8 && c >= 0 && c < 8;

    // Função melhorada para verificar se uma posição contém uma peça adversária
    const isOpponentPiece = (r: number, c: number) => {
      const targetPiece = board[r][c];
      if (player === 1) {
        return targetPiece === 2 || targetPiece === 4; // Peças azuis
      } else {
        return targetPiece === 1 || targetPiece === 3; // Peças vermelhas
      }
    };

    // Funão para verificar capturas da rainha
    const checkKingCapture = (r: number, c: number, dr: number, dc: number) => {
      let currentRow = r + dr;
      let currentCol = c + dc;
      let foundOpponent = false;
      let opponentRow = -1;
      let opponentCol = -1;

      // Procurar por uma peça adversária
      while (isValidPosition(currentRow, currentCol)) {
        if (board[currentRow][currentCol] !== 0) {
          if (isOpponentPiece(currentRow, currentCol) && !foundOpponent) {
            foundOpponent = true;
            opponentRow = currentRow;
            opponentCol = currentCol;
            currentRow += dr;
            currentCol += dc;
          } else {
            break; // Encontrou outra peça, para a busca
          }
        } else if (foundOpponent) {
          // Encontrou espaço vazio após a peça adversária
          moves.push({
            row: currentRow,
            col: currentCol,
            isCapture: true,
            capturedRow: opponentRow,
            capturedCol: opponentCol
          });
          break;
        } else {
          currentRow += dr;
          currentCol += dc;
        }
      }
    };

    // Função para verificar capturas de peças normais
    const checkNormalCapture = (r: number, c: number, dr: number, dc: number) => {
      const jumpRow = r + dr;
      const jumpCol = c + dc;
      const captureRow = r + dr * 2;
      const captureCol = c + dc * 2;

      if (
        isValidPosition(captureRow, captureCol) &&
        isOpponentPiece(jumpRow, jumpCol) &&
        board[captureRow][captureCol] === 0
      ) {
        moves.push({
          row: captureRow,
          col: captureCol,
          isCapture: true,
          capturedRow: jumpRow,
          capturedCol: jumpCol
        });
      }
    };

    // Verificar todas as direções para capturas
    const directions = [[-1, -1], [-1, 1], [1, -1], [1, 1]];
    
    // Se for rainha, usar lógica específica para capturas de rainha
    if (isKing) {
      directions.forEach(([dr, dc]) => {
        checkKingCapture(row, col, dr, dc);
      });
    } else {
      // Para peças normais, verificar capturas em todas as direções
      directions.forEach(([dr, dc]) => {
        checkNormalCapture(row, col, dr, dc);
      });
    }

    // Se não houver capturas e não estiver procurando apenas capturas
    if (!onlyCaptures && moves.length === 0) {
      if (!isKing) {
        // Movimentos normais apenas para frente
        const forwardDirections = player === 1 ? [[1, -1], [1, 1]] : [[-1, -1], [-1, 1]];
        forwardDirections.forEach(([dr, dc]) => {
          const newRow = row + dr;
          const newCol = col + dc;
          if (isValidPosition(newRow, newCol) && board[newRow][newCol] === 0) {
            moves.push({ row: newRow, col: newCol });
          }
        });
      } else {
        // Movimentos da rainha em todas as direções
        directions.forEach(([dr, dc]) => {
          let currentRow = row + dr;
          let currentCol = col + dc;
          while (isValidPosition(currentRow, currentCol) && board[currentRow][currentCol] === 0) {
            moves.push({ row: currentRow, col: currentCol });
            currentRow += dr;
            currentCol += dc;
          }
        });
      }
    }

    return moves;
  };

  // Nova função para calcular movimentos usando um tabuleiro simulado
  const calculateValidMovesForSimulation = (
    row: number, 
    col: number, 
    simulatedBoard: number[][], 
    currentPlayer: number
  ): ValidMove[] => {
    const moves: ValidMove[] = [];
    const piece = simulatedBoard[row][col];
    const isKing = piece === 3 || piece === 4;
    const player = currentPlayer; // Usar o jogador atual explicitamente

    const isValidPosition = (r: number, c: number) => r >= 0 && r < 8 && c >= 0 && c < 8;

    const isOpponentPiece = (r: number, c: number) => {
      const targetPiece = simulatedBoard[r][c];
      if (player === 1) {
        return targetPiece === 2 || targetPiece === 4; // Somente peças azuis
      } else {
        return targetPiece === 1 || targetPiece === 3; // Somente peças vermelhas
      }
    };

    // Função para verificar capturas
    const checkCapture = (r: number, c: number, dr: number, dc: number) => {
      const jumpRow = r + dr;
      const jumpCol = c + dc;
      const captureRow = r + dr * 2;
      const captureCol = c + dc * 2;

      if (
        isValidPosition(captureRow, captureCol) &&
        isOpponentPiece(jumpRow, jumpCol) && // Verifica se é peça adversária
        simulatedBoard[captureRow][captureCol] === 0 // Verifica se o destino está vazio
      ) {
        moves.push({
          row: captureRow,
          col: captureCol,
          isCapture: true,
          capturedRow: jumpRow,
          capturedCol: jumpCol
        });
      }
    };

    // Verificar todas as direções para capturas
    const directions = [[-1, -1], [-1, 1], [1, -1], [1, 1]];
    directions.forEach(([dr, dc]) => {
      checkCapture(row, col, dr, dc);
    });

    return moves;
  };

  // Melhorar a função hasMoreCaptures para verificar todas as direções
  const hasMoreCaptures = (row: number, col: number): boolean => {
    const captures = calculateValidMoves(row, col, true);
    return captures.some(move => move.isCapture);
  };

  // Verificar vitória
  const checkWin = (board: number[][]): string | null => {
    let player1Pieces = 0;
    let player2Pieces = 0;

    for (let row = 0; row < 8; row++) {
      for (let col = 0; col < 8; col++) {
        const piece = board[row][col];
        if (piece === 1 || piece === 3) player1Pieces++;
        if (piece === 2 || piece === 4) player2Pieces++;
      }
    }

    if (player1Pieces === 0) return game?.player2_id || null;
    if (player2Pieces === 0) return game?.player1_id || null;
    return null;
  };

  // Função auxiliar para encontrar peças com capturas obrigatórias
  const findMandatoryPieces = (board: number[][]): Array<{ row: number; col: number }> => {
    const pieces: Array<{ row: number; col: number }> = [];
    
    for (let r = 0; r < 8; r++) {
      for (let c = 0; c < 8; c++) {
        const piece = board[r][c];
        const isCurrentPlayerPiece = 
          (game?.current_player === 1 && (piece === 1 || piece === 3)) ||
          (game?.current_player === 2 && (piece === 2 || piece === 4));

        if (isCurrentPlayerPiece) {
          const moves = calculateValidMoves(r, c);
          if (moves.some(move => move.isCapture)) {
            pieces.push({ row: r, col: c });
          }
        }
      }
    }
    
    return pieces;
  };

  const handlePlayAgain = async () => {
    if (!game?.id) return;

    // Reiniciar o jogo com um novo tabuleiro
    const initialBoard = Array(8).fill(0).map(() => Array(8).fill(0));
    
    for (let row = 0; row < 3; row++) {
      for (let col = 0; col < 8; col++) {
        if ((row + col) % 2 === 1) {
          initialBoard[row][col] = 1;
        }
      }
    }
    
    for (let row = 5; row < 8; row++) {
      for (let col = 0; col < 8; col++) {
        if ((row + col) % 2 === 1) {
          initialBoard[row][col] = 2;
        }
      }
    }

    try {
      // Limpar o histórico de movimentos
      await supabase
        .from('moves')
        .delete()
        .eq('game_id', game.id);

      // Atualizar o jogo
      const { error } = await supabase
        .from('games')
        .update({
          board: initialBoard,
          current_player: 1,
          winner_id: null,
          updated_at: new Date().toISOString()
        })
        .eq('room_id', roomId);

      if (error) throw error;
      
      // Resetar o estado local do histórico
      setMoveHistory([]);
      
      await refetch();
      toast.success('Novo jogo iniciado!');
    } catch (err) {
      console.error('Erro ao reiniciar jogo:', err);
      toast.error('Erro ao reiniciar jogo');
    }
  };

  // Renderizar o chat e histórico
  const ChatAndHistory = () => (
    <div className="w-full h-full">
      {game && (
        <GameChat 
          gameId={game.id} 
          moveHistory={moveHistory}
          player1Name={player1Name}
          player2Name={player2Name}
          player1Id={game.player1_id}
          player2Id={game.player2_id}
        />
      )}
    </div>
  );

  // Atualizar o componente SpectatorControls
  const SpectatorControls = () => (
    <div className="flex gap-2 justify-center mt-4">
      <Button 
        onClick={() => setViewingPlayer(1)}
        variant={viewingPlayer === 1 ? "default" : "outline"}
      >
        Ver como Jogador 1
      </Button>
      <Button 
        onClick={() => setViewingPlayer(2)}
        variant={viewingPlayer === 2 ? "default" : "outline"}
      >
        Ver como Jogador 2
      </Button>
    </div>
  );

  // Componente para mostrar o timer
  const GameTimer = () => (
    <div className="text-center mb-4">
      <div className={cn(
        "text-2xl font-bold",
        timeLeft <= 10 ? "text-red-500 animate-pulse" : "text-white"
      )}>
        {timeLeft}s
      </div>
    </div>
  );

  // Efeito para escutar atualizações do histórico
  useEffect(() => {
    if (!game?.id) return;

    // Carregar histórico inicial
    const loadMoveHistory = async () => {
      const { data, error } = await supabase
        .from('moves')
        .select('*, games!inner(*)')
        .eq('game_id', game.id)
        .order('created_at', { ascending: true });

      if (error) {
        console.error('Erro ao carregar histórico:', maskSensitiveInfo(error));
        return;
      }

      const history = data.map(move => ({
        player: move.current_player,
        from: move.from_position,
        to: move.to_position,
        type: move.is_capture ? 'captura' : move.is_promotion ? 'promoção' : 'movimento'
      }));

      setMoveHistory(history);
    };

    loadMoveHistory();

    // Usar o mesmo canal para todas as atualizações
    const channel = supabase
      .channel(`game:${game.id}`)
      .on(
        'postgres_changes',
        {
          event: '*',
          schema: 'public',
          table: 'games',
          filter: `id=eq.${game.id}`
        },
        (payload) => {
          console.log('Game update received:', payload);
          refetch();
        }
      )
      .on(
        'postgres_changes',
        {
          event: 'INSERT',
          schema: 'public',
          table: 'moves',
          filter: `game_id=eq.${game.id}`
        },
        (payload) => {
          console.log('Move update received:', payload);
          loadMoveHistory();
        }
      )
      .subscribe((status) => {
        console.log('Channel status:', status);
      });

    return () => {
      channel.unsubscribe();
    };
  }, [game?.id, refetch]);

  // Componente MessageBubble interno
  const MessageBubble = React.memo(({ message }: { message: ChatMessage }) => {
    const isMyMessage = message.userId === session?.user?.id;
    const userProfile = profiles[message.userId];
    const displayName = userProfile?.display_name || 
                       `@${userProfile?.username}` || 
                       'Jogador';
    const avatarUrl = userProfile?.avatar_url || 
                     generateGradientAvatar(displayName);
    const messageTime = format(new Date(message.createdAt), 'HH:mm');

    return (
      <div className={cn(
        "flex items-start gap-2 group",
        isMyMessage ? "flex-row-reverse" : "flex-row"
      )}>
        <Avatar className="w-8 h-8 shrink-0">
          <AvatarImage src={avatarUrl} />
          <AvatarFallback>{displayName[0]}</AvatarFallback>
        </Avatar>

        <div className={cn(
          "flex flex-col",
          isMyMessage ? "items-end" : "items-start"
        )}>
          <div className={cn(
            "px-3 py-2 rounded-2xl max-w-[280px] break-words",
            isMyMessage 
              ? "bg-blue-600 text-white rounded-br-none" 
              : "bg-zinc-800 text-zinc-100 rounded-bl-none"
          )}>
            <div className="text-xs font-medium mb-1 opacity-90">
              {displayName}
            </div>
            <p className="text-sm">{message.message}</p>
          </div>
          <span className="text-xs text-zinc-500 px-2 opacity-0 group-hover:opacity-100 transition-opacity">
            {messageTime}
          </span>
        </div>
      </div>
    );
  });

  MessageBubble.displayName = 'MessageBubble';

  // Função para reiniciar o jogo
  const handleRestartGame = async () => {
    if (!game || !session?.user) return;

    try {
      const { error } = await supabase
        .from('games')
        .update({
          board: Array(8).fill(0).map(() => Array(8).fill(0)),
          current_player: 1,
          winner_id: null,
          last_move_time: new Date().toISOString()
        })
        .eq('room_id', roomId);

      if (error) throw error;
      toast.success('Jogo reiniciado!');
    } catch (err) {
      console.error('Erro ao reiniciar jogo:', err);
      toast.error('Erro ao reiniciar o jogo');
    }
  };

  // Adicionar useEffect para mostrar diálogo quando um novo jogador entrar
  useEffect(() => {
    if (!game || !session?.user) return;

    // Se um novo jogador entrou e existe um jogador que já estava na sala
    const wasWaitingForPlayer = !game.player2_id && game.player1_id && game.player1_id !== session.user.id;
    if (wasWaitingForPlayer && game.player2_id) {
      // Perguntar ao jogador 1 se quer reiniciar
      const dialog = confirm('Um novo jogador entrou! Deseja reiniciar o jogo?');
      if (dialog) {
        handleRestartGame();
      }
    }
  }, [game?.player2_id]);

  // Adicionar estado para mensagem de status
  const [statusMessage, setStatusMessage] = useState<string>('');

  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;

    const handleMouseMove = (e: MouseEvent) => {
      const { clientX, clientY } = e;
      const { width, height } = container.getBoundingClientRect();
      
      const x = (clientX - width / 2) / width;
      const y = (clientY - height / 2) / height;
      
      container.style.setProperty('--mouse-x', x.toString());
      container.style.setProperty('--mouse-y', y.toString());
    };

    container.addEventListener('mousemove', handleMouseMove);
    return () => container.removeEventListener('mousemove', handleMouseMove);
  }, []);

  const handleGameEnd = async (winnerId: string | null, loserId: string | null) => {
    if (!game?.id || !session?.user?.id) return;

    // Garantir que apenas o vencedor atualize suas próprias estatísticas
    if (winnerId !== session.user.id) {
      return;
    }

    try {
      // Atualizar apenas as estatísticas gerais
      await supabase.rpc('update_game_statistics', {
        winner_id: winnerId,
        loser_id: loserId || '00000000-0000-0000-0000-000000000000'
      });
      
      toast.success('Jogo finalizado! As estatísticas foram atualizadas.');
      refetch();
    } catch (error) {
      console.error('Erro ao atualizar estatísticas:', error);
      toast.error('Erro ao atualizar estatísticas do jogo');
    }
  };

  // Atualizar useEffect para considerar jogos com convidados
  useEffect(() => {
    if (!game?.id || !game.winner_id || !session?.user?.id) return;

    const updateGameStats = async () => {
      const loserId = game.winner_id === game.player1_id ? game.player2_id : game.player1_id;
      await handleGameEnd(game.winner_id, loserId);
    };

    updateGameStats();
  }, [game?.winner_id]); // Dependência reduzida apenas ao winner_id

  const [selectedPlayer, setSelectedPlayer] = useState<string | null>(null);

  // Adicionar função para obter texto do status
  const getStatusText = () => {
    if (!game) return 'Carregando...';
    if (game.winner_id) return 'Partida finalizada';
    if (!hasTwoPlayers()) return 'Aguardando jogador';
    return 'Partida em andamento';
  };

  // Adicionar função para obter cor do status
  const getStatusColor = () => {
    if (!game) return 'text-gray-400';
    if (game.winner_id) return 'text-blue-400';
    if (!hasTwoPlayers()) return 'text-yellow-400';
    return 'text-green-400';
  };

  const analytics = useAnalytics();

  useEffect(() => {
    const gameId = game?.id;
    if (!gameId) return;
    
    const startTime = Date.now(); // Marca o tempo inicial quando o jogo começa
    analytics.trackGameStart(gameId);
    
    return () => {
      const duration = Math.floor((Date.now() - startTime) / 1000); // Calcula quantos segundos se passaram
      analytics.trackGameEnd(gameId, 'victory', duration);
    };
  }, [game?.id, analytics]);

  useEffect(() => {
    if (game?.winner_id && game?.id) {
      const result = game.winner_id === (isPlayer1 ? game.player1_id : game.player2_id) 
        ? 'victory' 
        : 'defeat';
      
      analytics.trackGameEnd(game.id, result, 0); // Aqui poderíamos implementar o cálculo da duração real do jogo
    }
  }, [game?.winner_id, game?.id, isPlayer1, game?.player1_id, game?.player2_id, analytics]);

  // Função para rastrear movimentos
  const trackMove = useCallback((fromRow: number, fromCol: number, toRow: number, toCol: number) => {
    if (game?.id) {
      analytics.trackMove(game.id, moveHistory.length + 1);
    }
  }, [game?.id, moveHistory.length, analytics]);

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gradient-to-b from-[#000510] to-[#001233]">
        <Loader2 className="w-8 h-8 animate-spin text-white" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gradient-to-br from-zinc-900 to-zinc-800">
        <Card className="p-8 text-center">
          <h2 className="text-xl font-bold text-red-500 mb-4">Erro</h2>
          <p className="text-gray-400">{error}</p>
          <Button
            onClick={() => navigate('/rooms')}
            className="mt-4 bg-zinc-800 hover:bg-zinc-700"
          >
            Voltar para Salas
          </Button>
        </Card>
      </div>
    );
  }

  if (!game) return null;

  const player1Name = isPlayer1 
    ? (isGuest ? playerName : getDisplayName(session?.user?.id, session?.user?.email))
    : getDisplayName(game?.player1_id, game?.player1_name);
    
  const player2Name = isPlayer2 
    ? (isGuest ? playerName : getDisplayName(session?.user?.id, session?.user?.email))
    : getDisplayName(game?.player2_id, game?.player2_name);

  const board = getBoard();

  // Adicionar cálculo de peças
  const [player1Pieces, player2Pieces] = board.reduce(
    (acc, row) => {
      row.forEach(piece => {
        if (piece === 1 || piece === 3) acc[0]++;
        if (piece === 2 || piece === 4) acc[1]++;
      });
      return acc;
    },
    [0, 0]
  );

  // Remover restrições de visualização de perfil para convidados
  const handlePlayerClick = (playerId: string | null, playerName: string | null) => {
    if (playerId) {
      setSelectedPlayer(playerId);
    }
  };

  return (
    <div 
      ref={containerRef}
      className="relative min-h-screen overflow-hidden nebula-bg"
    >
      {/* Círculos gradientes flutuantes */}
      <div className="absolute inset-0 overflow-hidden pointer-events-none">
        {[...Array(3)].map((_, i) => (
          <div
            key={i}
            className={cn(
              "absolute w-96 h-96 rounded-full blur-3xl opacity-20",
              i === 0 && "-top-48 -left-48 bg-blue-500",
              i === 1 && "top-1/2 -right-48 bg-purple-500",
              i === 2 && "bottom-0 left-1/2 bg-red-500"
            )}
          />
        ))}
      </div>

      {/* Partículas brilhantes */}
      <div className="absolute inset-0 pointer-events-none opacity-30">
        {[...Array(30)].map((_, i) => (
          <div
            key={i}
            className="absolute w-1 h-1 bg-blue-400 rounded-full animate-twinkle"
            style={{
              left: `${Math.random() * 100}%`,
              top: `${Math.random() * 100}%`,
              animationDelay: `${Math.random() * 3}s`,
              animationDuration: `${2 + Math.random() * 3}s`
            }}
          />
        ))}
      </div>

      {/* Overlay com gradiente suave */}
      <div 
        className="absolute inset-0 pointer-events-none"
        style={{
          background: `radial-gradient(circle at calc(50% + calc(var(--mouse-x, 0) * 30%)) calc(50% + calc(var(--mouse-y, 0) * 30%)), transparent 0%, rgba(0, 18, 51, 0.3) 100%)`,
          transition: 'background 0.2s ease-out'
        }}
      />

      {/* Conteúdo principal */}
      <div className="relative z-10 min-h-screen">
        <div className="min-h-screen flex items-start justify-center p-1 sm:p-4">
          <Card className="w-full max-w-[1600px] min-h-[600px] h-[calc(100vh-32px)] p-2 bg-zinc-900/40 backdrop-blur-md border-zinc-800/50 flex flex-col mt-[20px] sm:mt-0">
            {/* Header com botão de sair */}
            <div className="flex items-center gap-2 h-12 px-2 bg-zinc-900/50 mb-2 rounded-md border border-white/5">
              <Button
                variant="outline"
                className="bg-zinc-800/50 text-white hover:bg-zinc-700/50 h-8 px-2 flex items-center shrink-0 border-zinc-700/50"
                onClick={handleLeaveGame}
              >
                <ArrowLeft className="w-4 h-4" />
                <span className="ml-1 text-sm">Sair</span>
              </Button>

              <div className="flex-1 text-center">
                <h1 className="text-base font-bold text-white/90 truncate">
                  Sala: {roomId}
                </h1>
                {statusMessage && (
                  <p className="text-xs text-yellow-400/90 animate-pulse truncate">
                    {statusMessage}
                  </p>
                )}
              </div>

              <div className="w-[72px] shrink-0"></div>
            </div>

            {/* Layout principal */}
            <div className="flex-1 flex flex-col md:flex-row gap-4 overflow-y-auto md:overflow-hidden items-start md:items-center justify-start md:justify-center p-2 bg-zinc-900/30 rounded-lg border border-white/5">
              {/* Coluna da esquerda - Avatares Desktop */}
              <div className="hidden md:flex w-[15%] max-w-[140px] min-w-[120px] flex-shrink-0 flex-col justify-between h-[calc(75vh_-_32px)]">
                {/* Avatar do oponente (topo) */}
                <div className="flex flex-col items-center">
                  <Avatar 
                    className={cn(
                      "w-24 h-24 ring-2 cursor-pointer hover:ring-4 transition-all",
                      isPlayer2 
                        ? "ring-red-500 shadow-lg shadow-red-500/20"
                        : "ring-blue-500 shadow-lg shadow-blue-500/20"
                    )}
                    onClick={() => handlePlayerClick(
                      isPlayer2 ? game?.player1_id : game?.player2_id,
                      isPlayer2 ? game?.player1_name : game?.player2_name
                    )}
                  >
                    <AvatarImage 
                      src={isPlayer2 ? player1Avatar : player2Avatar} 
                      alt={isPlayer2 ? player1Name : player2Name}
                    />
                    <AvatarFallback className={cn(
                      "text-xl",
                      isPlayer2 
                        ? "bg-gradient-to-br from-red-600 to-red-700" 
                        : "bg-gradient-to-br from-blue-600 to-blue-700"
                    )}>
                      {(isPlayer2 ? player1Name : player2Name)?.charAt(0)}
                    </AvatarFallback>
                  </Avatar>
                  <div className="text-center mt-2">
                    <div className="font-bold text-sm text-white">
                      {isPlayer2 ? player1Name : player2Name}
                    </div>
                    <div className={cn(
                      "text-xs font-medium text-red-400",
                      isPlayer2 ? "text-red-400" : "text-blue-400"
                    )}>
                      Peças {isPlayer2 ? "Vermelhas" : "Azuis"}
                    </div>
                  </div>
                </div>

                {/* Avatar do jogador (base) */}
                <div className="flex flex-col items-center">
                  <Avatar 
                    className={cn(
                      "w-24 h-24 ring-2 cursor-pointer hover:ring-4 transition-all",
                      isPlayer2 
                        ? "ring-blue-500 shadow-lg shadow-blue-500/20"
                        : "ring-red-500 shadow-lg shadow-red-500/20"
                    )}
                    onClick={() => handlePlayerClick(
                      isPlayer2 ? game?.player2_id : game?.player1_id,
                      isPlayer2 ? game?.player2_name : game?.player1_name
                    )}
                  >
                    <AvatarImage 
                      src={isPlayer2 ? player2Avatar : player1Avatar} 
                      alt={isPlayer2 ? player2Name : player1Name}
                    />
                    <AvatarFallback className={cn(
                      "text-xl",
                      isPlayer2 
                        ? "bg-gradient-to-br from-blue-600 to-blue-700"
                        : "bg-gradient-to-br from-red-600 to-red-700"
                    )}>
                      {(isPlayer2 ? player2Name : player1Name)?.charAt(0)}
                    </AvatarFallback>
                  </Avatar>
                  <div className="text-center mt-2">
                    <div className="font-bold text-sm text-white">
                      {isPlayer2 ? player2Name : player1Name}
                    </div>
                    <div className={cn(
                      "text-xs font-medium",
                      isPlayer2 ? "text-blue-400" : "text-red-400"
                    )}>
                      Peças {isPlayer2 ? "Azuis" : "Vermelhas"}
                    </div>
                  </div>
                </div>
              </div>

              {/* Área central */}
              <div className="flex-1 flex flex-col items-center w-full max-w-[min(75vh,750px)]">
                {/* Avatares versão mobile */}
                <div className="flex md:hidden justify-between w-full p-2 bg-black/20 rounded-lg mb-2">
                  {/* Avatar do oponente */}
                  <div className="flex items-center gap-2">
                    <Avatar className={cn(
                      "w-10 h-10 ring-2",
                      isPlayer2 
                        ? "ring-red-500" 
                        : "ring-blue-500"
                    )}>
                      <AvatarImage 
                        src={isPlayer2 ? player1Avatar : player2Avatar} 
                        alt={isPlayer2 ? player1Name : player2Name}
                      />
                      <AvatarFallback>{(isPlayer2 ? player1Name : player2Name)?.charAt(0)}</AvatarFallback>
                    </Avatar>
                    <div>
                      <div className="font-medium text-white text-xs">
                        {isPlayer2 ? player1Name : player1Name}
                      </div>
                      <div className={cn(
                        "text-xs",
                        isPlayer2 ? "text-red-400" : "text-blue-400"
                      )}>
                        {isPlayer2 ? "Vermelhas" : "Azuis"}
                      </div>
                    </div>
                  </div>

                  {/* Avatar do jogador */}
                  <div className="flex items-center gap-2">
                    <div className="text-right">
                      <div className="font-medium text-white text-xs">
                        {isPlayer2 ? player2Name : player1Name}
                      </div>
                      <div className={cn(
                        "text-xs",
                        isPlayer2 ? "text-blue-400" : "text-red-400"
                      )}>
                        {isPlayer2 ? "Azuis" : "Vermelhas"}
                      </div>
                    </div>
                    <Avatar className={cn(
                      "w-10 h-10 ring-2",
                      isPlayer2 
                        ? "ring-blue-500"
                        : "ring-red-500"
                    )}>
                      <AvatarImage 
                        src={isPlayer2 ? player2Avatar : player1Avatar} 
                        alt={isPlayer2 ? player2Name : player1Name}
                      />
                      <AvatarFallback>{(isPlayer2 ? player2Name : player1Name)?.charAt(0)}</AvatarFallback>
                    </Avatar>
                  </div>
                </div>

                {/* Tabuleiro */}
                <div className="aspect-square w-full flex items-center justify-center px-2 md:px-0">
                  <div className="aspect-square w-full max-w-[min(100vw_-_32px,_calc(75vh_-_32px))]">
                    <CheckerBoard
                      board={getBoard()}
                      onSquareClick={handleSquareClick}
                      selectedPiece={selectedPiece}
                      validMoves={validMoves}
                      currentPlayer={game.current_player}
                      gameStarted={gameStarted}
                      isPlayer2={isPlayer2}
                      mandatoryPieces={mandatoryPieces}
                      isMyTurn={isMyTurn}
                      isMobile={isMobile}
                      canJoinGame={canJoinGame}
                      onJoinGame={joinGame}
                      player1Id={game?.player1_id}
                      player2Id={game?.player2_id}
                    />
                  </div>
                </div>

                {/* Versão mobile do chat e histórico */}
                <div className="md:hidden w-full flex flex-col gap-5 mt-2 p-2">
                  {/* Mensagem para usuários convidados */}
                  {isGuest && (
                    <Card className="p-2 bg-blue-900/20 border border-blue-500/20">
                      <p className="text-zinc-300 text-sm">
                        Você está jogando no modo convidado. Para participar do ranking e ter acesso a skins personalizadas, faça login ou crie uma conta.
                      </p>
                    </Card>
                  )}

                  {/* Chat */}
                  <Card className="h-[270px] bg-zinc-900/50 border-zinc-800 flex flex-col">
                    <div className="p-1 border-b border-zinc-800">
                      <h3 className="font-semibold text-white text-sm">Chat da Partida</h3>
                    </div>
                    <div className="flex-1 overflow-hidden">
                      {game && (
                        <GameChat 
                          gameId={game.id} 
                          moveHistory={moveHistory}
                          player1Name={player1Name}
                          player2Name={player2Name}
                          player1Id={game.player1_id}
                          player2Id={game.player2_id}
                        />
                      )}
                    </div>
                  </Card>

                  {/* Histórico */}
                  <Card className="h-[200px] bg-zinc-900/50 border-zinc-800 flex flex-col">
                    <div className="p-1 border-b border-zinc-800">
                      <h3 className="font-semibold text-white text-sm">Histórico de Movimentos</h3>
                    </div>
                    <div className="flex-1 overflow-hidden">
                      <GameHistory
                        gameId={game.id}
                        moveHistory={moveHistory}
                        player1Name={player1Name}
                        player2Name={player2Name}
                      />
                    </div>
                  </Card>
                </div>
              </div>

              {/* Coluna da direita - Chat e Histórico (desktop) */}
              <div className="hidden md:flex w-[25%] max-w-[260px] min-w-[250px] flex-shrink-0 flex-col gap-4 h-[calc(75vh_-_32px)]">
                {/* Mensagem para usuários convidados */}
                {isGuest && (
                  <Card className="p-2 bg-blue-900/20 border border-blue-500/20">
                    <p className="text-zinc-300 text-sm">
                      Você está jogando no modo convidado. Para participar do ranking e ter acesso a skins personalizadas, faça login ou crie uma conta.
                    </p>
                  </Card>
                )}

                {/* Chat */}
                <Card className="flex-[1.8] bg-zinc-900/30 border-zinc-800/50 flex flex-col min-h-0">
                  <div className="p-2 border-b border-zinc-700/30">
                    <h3 className="font-semibold text-white/90 text-sm">Chat da Partida</h3>
                  </div>
                  <div className="flex-1 overflow-hidden min-w-0">
                    {game && (
                      <GameChat 
                        gameId={game.id} 
                        moveHistory={moveHistory}
                        player1Name={player1Name}
                        player2Name={player2Name}
                        player1Id={game.player1_id}
                        player2Id={game.player2_id}
                      />
                    )}
                  </div>
                </Card>

                {/* Histórico */}
                <Card className="flex-1 bg-zinc-900/30 border-zinc-800/50 flex flex-col min-h-0">
                  <div className="p-2 border-b border-zinc-700/30">
                    <h3 className="font-semibold text-white/90 text-sm">Histórico de Movimentos</h3>
                  </div>
                  <div className="flex-1 overflow-hidden min-w-0">
                    <GameHistory
                      gameId={game.id}
                      moveHistory={moveHistory}
                      player1Name={player1Name}
                      player2Name={player2Name}
                    />
                  </div>
                </Card>
              </div>
            </div>

            {/* Timer e outros elementos */}
            {/* {gameStarted && isMyTurn && (
              <div className="absolute top-2 left-1/2 -translate-x-1/2 z-10">
                <GameTimer />
              </div>
            )} */}

            {/* Modal de vitória/derrota */}
            {(game.winner_id || player1Pieces === 0 || player2Pieces === 0) && (
              <motion.div 
                initial={{ opacity: 0, scale: 0.8 }}
                animate={{ opacity: 1, scale: 1 }}
                className="fixed inset-0 flex items-center justify-center bg-black/70 backdrop-blur-md z-50"
              >
                <motion.div 
                  initial={{ y: 20 }}
                  animate={{ y: 0 }}
                  className="bg-zinc-900/80 backdrop-blur-md p-6 sm:p-8 rounded-xl border border-zinc-700/50 shadow-2xl max-w-md w-full mx-4"
                >
                  {(
                    // Caso de usuário logado vs logado
                    (!isGuest && game.winner_id && (
                      (isPlayer1 && game.winner_id === game.player1_id) ||
                      (isPlayer2 && game.winner_id === game.player2_id)
                    )) ||
                    // Caso de convidado vs convidado ou convidado vs logado
                    (isGuest && (
                      (isPlayer1 && player2Pieces === 0) ||
                      (isPlayer2 && player1Pieces === 0)
                    )) ||
                    // Caso especial: logado vs convidado
                    (!isGuest && !game.winner_id && (
                      (isPlayer1 && player2Pieces === 0) ||
                      (isPlayer2 && player1Pieces === 0)
                    ))
                  ) ? (
                    <div className="text-center space-y-4">
                      <motion.div
                        animate={{ rotate: [0, 10, -10, 0] }}
                        transition={{ duration: 0.5, repeat: 3 }}
                        className="text-6xl sm:text-7xl mb-4"
                      >
                        👑
                      </motion.div>
                      <h2 className="text-2xl sm:text-3xl font-bold text-green-500">
                        Você Venceu!
                      </h2>
                      <p className="text-zinc-400">
                        Parabéns! Você é o grande campeão desta partida!
                      </p>
                    </div>
                  ) : (
                    <div className="text-center space-y-4">
                      <motion.div
                        animate={{ y: [0, -10, 0] }}
                        transition={{ duration: 0.5, repeat: 3 }}
                        className="text-6xl sm:text-7xl mb-4"
                      >
                        😔
                      </motion.div>
                      <h2 className="text-2xl sm:text-3xl font-bold text-red-500">
                        Você Perdeu
                      </h2>
                      <p className="text-zinc-400">
                        Não foi dessa vez, mas continue tentando!
                      </p>
                    </div>
                  )}
                  
                  <div className="mt-8 flex flex-col sm:flex-row gap-3 justify-center">
                    <Button
                      onClick={handlePlayAgain}
                      className="bg-green-600 hover:bg-green-700 text-white"
                    >
                      Jogar Novamente
                    </Button>
                    <Button
                      onClick={() => navigate('/rooms')}
                      variant="outline"
                      className="bg-zinc-800 text-white hover:bg-zinc-700"
                    >
                      Voltar para Salas
                    </Button>
                  </div>
                </motion.div>
              </motion.div>
            )}
          </Card>
        </div>
      </div>

      {/* Vinheta suave nas bordas */}
      <div className="absolute inset-0 pointer-events-none"
           style={{
             boxShadow: 'inset 0 0 200px rgba(0,24,69,0.7)'
           }} 
      />

      {selectedPlayer && (
        <PlayerProfileCard
          userId={selectedPlayer}
          isOpen={true}
          onClose={() => setSelectedPlayer(null)}
        />
      )}
    </div>
  );
};

export default GameRoom;