import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { Trophy, Crown, Medal, Calendar, Globe, RefreshCw } from 'lucide-react';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { Button } from '@/components/ui/button';
import { RankingTable } from '@/components/RankingTable';
import { PlayerProfileCard } from '@/components/PlayerProfileCard';
import { useSupabase } from '@/hooks/use-supabase';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';

type RankingPeriod = 'daily' | 'weekly' | 'monthly' | 'global';

interface RankingPlayer {
  user_id: string;
  wins: number;
  username: string;
  display_name: string | null;
  equipped_skin_id: string | null;
  avatar_skin: {
    image_url: string;
  } | null;
  rank_period: string;
}

export default function RankingPage() {
  const { supabase } = useSupabase();
  const [selectedPeriod, setSelectedPeriod] = useState<RankingPeriod>('daily');
  const [players, setPlayers] = useState<RankingPlayer[]>([]);
  const [loading, setLoading] = useState(true);
  const [selectedPlayer, setSelectedPlayer] = useState<string | null>(null);
  const [refreshKey, setRefreshKey] = useState(0);

  useEffect(() => {
    const fetchRanking = async () => {
      setLoading(true);
      try {
        let data;
        switch (selectedPeriod) {
          case 'daily':
            const { data: dailyData } = await supabase.rpc('get_daily_ranking');
            data = dailyData;
            break;
          case 'weekly':
            const { data: weeklyData } = await supabase.rpc('get_weekly_ranking');
            data = weeklyData;
            break;
          case 'monthly':
            const { data: monthlyData } = await supabase.rpc('get_monthly_ranking');
            data = monthlyData;
            break;
          case 'global':
            const { data: globalData } = await supabase.rpc('get_global_ranking');
            data = globalData;
            break;
        }
        setPlayers(data || []);
      } catch (error) {
        console.error('Erro ao buscar ranking:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchRanking();

    // Inscrever no canal de atualizações
    const channel = supabase
      .channel(`ranking_${selectedPeriod}`)
      .on('postgres_changes', {
        event: '*',
        schema: 'public',
        table: `${selectedPeriod}_wins`
      }, () => {
        fetchRanking();
      })
      .subscribe();

    return () => {
      channel.unsubscribe();
    };
  }, [selectedPeriod, refreshKey]);

  const handleRefresh = () => {
    setRefreshKey(prev => prev + 1);
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-[#000510] to-[#001233]">
      {/* Efeito de partículas */}
      <div className="absolute inset-0 overflow-hidden pointer-events-none">
        {[...Array(50)].map((_, i) => (
          <div
            key={i}
            className="absolute w-1 h-1 bg-blue-400 rounded-full animate-twinkle"
            style={{
              left: `${Math.random() * 100}%`,
              top: `${Math.random() * 100}%`,
              animationDelay: `${Math.random() * 3}s`,
              animationDuration: `${2 + Math.random() * 3}s`
            }}
          />
        ))}
      </div>

      {/* Conteúdo principal */}
      <div className="relative z-10 container mx-auto px-4 py-8">
        {/* Cabeçalho */}
        <div className="text-center mb-12">
          <motion.div
            initial={{ scale: 0.8, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            className="inline-block"
          >
            <Trophy className="w-16 h-16 text-yellow-500 mx-auto mb-4" />
          </motion.div>
          <h1 className="text-4xl font-bold text-white mb-2">
            Ranking de Jogadores
          </h1>
          <p className="text-zinc-400">
            Confira os melhores jogadores em diferentes períodos
          </p>
        </div>

        {/* Tabs de períodos */}
        <div className="max-w-4xl mx-auto">
          <Tabs
            value={selectedPeriod}
            onValueChange={(value) => setSelectedPeriod(value as RankingPeriod)}
            className="w-full"
          >
            <div className="flex items-center justify-between mb-6">
              <TabsList className="bg-zinc-900/50 border border-zinc-800">
                <TabsTrigger value="daily" className="data-[state=active]:bg-blue-600">
                  <Trophy className="w-4 h-4 mr-2" />
                  Diário
                </TabsTrigger>
                <TabsTrigger value="weekly" className="data-[state=active]:bg-blue-600">
                  <Calendar className="w-4 h-4 mr-2" />
                  Semanal
                </TabsTrigger>
                <TabsTrigger value="monthly" className="data-[state=active]:bg-blue-600">
                  <Medal className="w-4 h-4 mr-2" />
                  Mensal
                </TabsTrigger>
                <TabsTrigger value="global" className="data-[state=active]:bg-blue-600">
                  <Globe className="w-4 h-4 mr-2" />
                  Global
                </TabsTrigger>
              </TabsList>

              <Button
                variant="outline"
                size="sm"
                onClick={handleRefresh}
                className="bg-zinc-900/50 border-zinc-800 text-white hover:text-white"
              >
                <RefreshCw className="w-4 h-4 mr-2" />
                Atualizar
              </Button>
            </div>

            <motion.div
              key={selectedPeriod}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.2 }}
              className="bg-zinc-900/50 border border-zinc-800 rounded-lg p-6"
            >
              <TabsContent value="daily">
                <RankingTable
                  players={players}
                  period="daily"
                  isLoading={loading}
                  onPlayerClick={setSelectedPlayer}
                />
              </TabsContent>

              <TabsContent value="weekly">
                <RankingTable
                  players={players}
                  period="weekly"
                  isLoading={loading}
                  onPlayerClick={setSelectedPlayer}
                />
              </TabsContent>

              <TabsContent value="monthly">
                <RankingTable
                  players={players}
                  period="monthly"
                  isLoading={loading}
                  onPlayerClick={setSelectedPlayer}
                />
              </TabsContent>

              <TabsContent value="global">
                <RankingTable
                  players={players}
                  period="global"
                  isLoading={loading}
                  onPlayerClick={setSelectedPlayer}
                />
              </TabsContent>
            </motion.div>
          </Tabs>
        </div>
      </div>

      {/* Vinheta nas bordas */}
      <div 
        className="absolute inset-0 pointer-events-none"
        style={{
          boxShadow: 'inset 0 0 200px rgba(0,24,69,0.7)'
        }} 
      />

      {/* Modal de perfil do jogador */}
      {selectedPlayer && (
        <PlayerProfileCard
          userId={selectedPlayer}
          isOpen={true}
          onClose={() => setSelectedPlayer(null)}
        />
      )}
    </div>
  );
} 