import { useCallback } from 'react';
import { supabase } from '@/integrations/supabase/client';

const COOKIE_NAME = 'game_analytics';

interface AnalyticsData {
  sessionId: string;
  userId?: string;
  lastActivity: number;
}

const generateSessionId = () => {
  return Math.random().toString(36).substring(2) + Date.now().toString(36);
};

const getAnalyticsCookie = (): AnalyticsData | null => {
  const cookie = document.cookie
    .split('; ')
    .find(row => row.startsWith(COOKIE_NAME));
  
  if (cookie) {
    return JSON.parse(cookie.split('=')[1]);
  }
  return null;
};

const setAnalyticsCookie = (data: AnalyticsData) => {
  // Cookie expira em 30 dias
  const expires = new Date(Date.now() + 30 * 24 * 60 * 60 * 1000).toUTCString();
  document.cookie = `${COOKIE_NAME}=${JSON.stringify(data)}; expires=${expires}; path=/; SameSite=Strict`;
};

export const useAnalytics = () => {
  const getSessionData = useCallback(() => {
    let data = getAnalyticsCookie();
    
    if (!data) {
      data = {
        sessionId: generateSessionId(),
        lastActivity: Date.now()
      };
      setAnalyticsCookie(data);
    }
    
    return data;
  }, []);

  const trackEvent = useCallback(async (eventName: string, params: Record<string, any> = {}) => {
    const sessionData = getSessionData();
    
    // Remover campos que não existem na tabela
    const { duration, ...cleanParams } = params;
    
    const eventData = {
      ...cleanParams,
      event_name: eventName,
      session_id: sessionData.sessionId,
      timestamp: new Date().toISOString(),
      page_location: window.location.href,
      page_title: document.title,
      user_agent: navigator.userAgent,
      referrer: document.referrer,
      screen_resolution: `${window.screen.width}x${window.screen.height}`,
      language: navigator.language,
    };

    // Atualiza o timestamp da última atividade
    setAnalyticsCookie({
      ...sessionData,
      lastActivity: Date.now()
    });

    try {
      // Envia os dados para a tabela de analytics no Supabase
      const { error } = await supabase
        .from('analytics_events')
        .insert([eventData]);

      if (error) {
        console.error('Erro ao salvar analytics:', error);
      }
    } catch (error) {
      console.error('Erro ao enviar analytics:', error);
    }
  }, []);

  const trackGameStart = useCallback((gameId: string) => {
    trackEvent('game_start', {
      game_id: gameId,
      event_category: 'engagement',
      event_label: 'Início de Partida',
      value: 1,
      game_type: 'damas',
      game_mode: window.location.pathname.includes('guest') ? 'guest' : 'authenticated'
    });
  }, [trackEvent]);

  const trackGameEnd = useCallback((gameId: string, result: 'victory' | 'defeat' | 'draw', duration: number) => {
    trackEvent('game_end', {
      game_id: gameId,
      result,
      game_duration_seconds: duration,
      event_category: 'engagement',
      event_label: 'Fim de Partida',
      value: result === 'victory' ? 1 : 0,
      game_type: 'damas',
      game_mode: window.location.pathname.includes('guest') ? 'guest' : 'authenticated'
    });
  }, [trackEvent]);

  const trackMove = useCallback((gameId: string, moveNumber: number) => {
    trackEvent('move', {
      game_id: gameId,
      move_number: moveNumber,
      event_category: 'engagement',
      event_label: 'Movimento',
      value: moveNumber,
      game_type: 'damas'
    });
  }, [trackEvent]);

  const trackCapture = useCallback((gameId: string, moveNumber: number, pieceType: string) => {
    trackEvent('capture', {
      game_id: gameId,
      move_number: moveNumber,
      piece_type: pieceType,
      event_category: 'engagement',
      event_label: 'Captura de Peça'
    });
  }, [trackEvent]);

  const trackPromotion = useCallback((gameId: string, moveNumber: number) => {
    trackEvent('promotion', {
      game_id: gameId,
      move_number: moveNumber,
      event_category: 'engagement',
      event_label: 'Promoção de Peça'
    });
  }, [trackEvent]);

  const trackError = useCallback((gameId: string, errorType: string, errorMessage: string) => {
    trackEvent('game_error', {
      game_id: gameId,
      error_type: errorType,
      error_message: errorMessage,
      event_category: 'error',
      event_label: 'Erro no Jogo'
    });
  }, [trackEvent]);

  return {
    trackGameStart,
    trackGameEnd,
    trackMove,
    trackCapture,
    trackPromotion,
    trackError
  };
}; 