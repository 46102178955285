import { Toaster } from "@/components/ui/toaster";
import { Toaster as Sonner } from "@/components/ui/sonner";
import { TooltipProvider } from "@/components/ui/tooltip";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { BrowserRouter, Routes, Route, Navigate, useParams, useLocation } from "react-router-dom";
import { SessionContextProvider, useSession } from '@supabase/auth-helpers-react';
import { supabase } from "./integrations/supabase/client";
import Index from "./pages/Index";
import Login from "./pages/Login";
import Rooms from "./pages/Rooms";
import GameRoom from "./components/GameRoom";
import { Header } from "@/components/Header";
import { useSessionRefresh } from '@/hooks/use-session-refresh';
import { useMemo, useState } from 'react';
import AuthCallback from "./pages/AuthCallback";
import EmailConfirmation from "./pages/EmailConfirmation";
import { InstallButton } from './components/InstallButton';
import { SplashScreen } from './components/SplashScreen';
import { CookieConsent } from './components/CookieConsent';
import Ranking from './pages/Ranking';
import Forum from '@/pages/Forum';
import Topic from '@/pages/Topic';
import Tutorial from '@/pages/Tutorial';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 1,
      refetchOnWindowFocus: false
    }
  }
});

// Componente para proteger rotas que precisam de autenticação
const PrivateRoute = ({ children }: { children: React.ReactNode }) => {
  const session = useSession();
  
  if (!session) {
    return <Navigate to="/login" replace />;
  }
  
  return <>{children}</>;
};

// Wrapper para o GameRoom com proteção de rota
const GameRoomWrapper = () => {
  const { roomId } = useParams();
  const session = useSession();
  const [guestName, setGuestName] = useState<string | null>(localStorage.getItem('guestName'));
  
  if (!roomId) {
    return <Navigate to="/" replace />;
  }
  
  // Se não estiver logado e não tiver nome de convidado, gerar um nome aleatório
  if (!session?.user && !guestName) {
    const randomNumber = Math.floor(Math.random() * 1000);
    const newGuestName = `Convidado${randomNumber}`;
    localStorage.setItem('guestName', newGuestName);
    setGuestName(newGuestName);
  }
  
  return (
    <GameRoom 
      roomId={roomId} 
      playerName={session?.user?.email || guestName || 'Anônimo'} 
      isGuest={!session?.user}
    />
  );
};

// Componente de rotas que usa o contexto de sessão
const AppRoutes = () => {
  const session = useSession();
  const location = useLocation();
  useSessionRefresh();

  const isLoginPage = location.pathname === '/login';

  return (
    <div className="min-h-screen flex flex-col">
      {!isLoginPage && <Header />}
      <main className="flex-1">
        <Routes>
          <Route 
            path="/" 
            element={<Rooms />} 
          />
          <Route 
            path="/login" 
            element={session ? <Navigate to="/" replace /> : <Login />} 
          />
          <Route 
            path="/rooms" 
            element={<Navigate to="/" replace />} 
          />
          <Route 
            path="/room/:roomId" 
            element={<GameRoomWrapper />} 
          />
          <Route 
            path="/auth/callback" 
            element={<AuthCallback />} 
          />
          <Route 
            path="/auth/confirm" 
            element={<EmailConfirmation />} 
          />
          <Route 
            path="/ranking" 
            element={<Ranking />} 
          />
          <Route path="/forum" element={<Forum />} />
          <Route path="/forum/topic/:id" element={<Topic />} />
          <Route path="/tutorial" element={<Tutorial />} />
        </Routes>
      </main>
    </div>
  );
};

const App = () => {
  // Garantir que o cliente Supabase seja o mesmo durante toda a vida do app
  const supabaseClient = useMemo(() => supabase, []);

  return (
    <>
      <SplashScreen />
      <InstallButton />
      <SessionContextProvider supabaseClient={supabaseClient}>
        <QueryClientProvider client={queryClient}>
          <TooltipProvider>
            <BrowserRouter>
              <Toaster />
              <Sonner />
              <AppRoutes />
              <CookieConsent />
            </BrowserRouter>
          </TooltipProvider>
        </QueryClientProvider>
      </SessionContextProvider>
    </>
  );
};

export default App;