import React from 'react';
import { motion } from 'framer-motion';
import { Crown, Trophy, Medal } from 'lucide-react';
import { cn } from '@/lib/utils';
import { Avatar, AvatarImage, AvatarFallback } from '@/components/ui/avatar';
import { generateGradientAvatar } from '@/lib/avatar';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';

interface RankingPlayer {
  user_id: string;
  wins: number;
  username: string;
  display_name: string | null;
  equipped_skin_id: string | null;
  avatar_skin: {
    image_url: string;
  } | null;
  rank_period: string;
}

interface RankingTableProps {
  players: RankingPlayer[];
  period: 'daily' | 'weekly' | 'monthly' | 'global';
  isLoading?: boolean;
  onPlayerClick?: (userId: string) => void;
}

export function RankingTable({ players, period, isLoading, onPlayerClick }: RankingTableProps) {
  if (isLoading) {
    return (
      <div className="space-y-2">
        {[...Array(10)].map((_, i) => (
          <div 
            key={i}
            className="animate-pulse flex items-center gap-3 p-3 rounded-lg bg-zinc-800/30"
          >
            <div className="w-8 h-8 rounded-full bg-zinc-700" />
            <div className="flex-1">
              <div className="h-4 w-24 bg-zinc-700 rounded" />
              <div className="h-3 w-16 bg-zinc-700 rounded mt-2" />
            </div>
            <div className="h-6 w-12 bg-zinc-700 rounded" />
          </div>
        ))}
      </div>
    );
  }

  if (players.length === 0) {
    return (
      <div className="text-center py-8">
        <Trophy className="w-12 h-12 text-zinc-500 mx-auto mb-4" />
        <p className="text-zinc-400">Nenhum jogador encontrado</p>
        {period === 'daily' && (
          <p className="text-sm text-zinc-500 mt-2">
            Seja o primeiro a vencer hoje!
          </p>
        )}
      </div>
    );
  }

  const getPeriodText = () => {
    switch (period) {
      case 'daily':
        return 'Hoje';
      case 'weekly':
        return 'Esta Semana';
      case 'monthly':
        return 'Este Mês';
      case 'global':
        return 'Desde o Início';
    }
  };

  const getRankIcon = (position: number) => {
    if (position === 0) return <Crown className="w-6 h-6 text-yellow-500" />;
    if (position === 1) return <Medal className="w-6 h-6 text-zinc-300" />;
    if (position === 2) return <Medal className="w-6 h-6 text-amber-700" />;
    return <span className="text-zinc-500 font-bold">{position + 1}</span>;
  };

  return (
    <div className="space-y-2">
      <div className="flex items-center justify-between mb-4">
        <h3 className="text-lg font-semibold text-white">
          Ranking - {getPeriodText()}
        </h3>
        <div className="text-sm text-zinc-400">
          {players.length} jogadores
        </div>
      </div>

      {players.map((player, index) => {
        const avatarUrl = player.equipped_skin_id && player.avatar_skin
          ? player.avatar_skin.image_url
          : generateGradientAvatar(player.username || 'A');

        return (
          <motion.div
            key={player.user_id}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: index * 0.05 }}
            className={cn(
              "flex items-center gap-3 p-3 rounded-lg transition-colors cursor-pointer hover:bg-white/5",
              index === 0 ? "bg-yellow-500/10" :
              index === 1 ? "bg-zinc-400/10" :
              index === 2 ? "bg-amber-900/10" :
              "bg-zinc-800/30"
            )}
            onClick={() => onPlayerClick?.(player.user_id)}
          >
            <div className="w-8 h-8 flex items-center justify-center">
              {getRankIcon(index)}
            </div>

            <Avatar 
              className="w-10 h-10 ring-1 ring-white/10 hover:ring-white/30 transition-all"
            >
              <AvatarImage src={avatarUrl} alt={player.display_name || player.username} />
              <AvatarFallback className="bg-white/5">
                {(player.display_name || player.username)?.charAt(0)}
              </AvatarFallback>
            </Avatar>

            <div className="flex-1 min-w-0">
              <p className="font-medium text-white truncate">
                {player.display_name || `@${player.username}`}
              </p>
              <p className="text-sm text-zinc-400 truncate">
                {player.username && `@${player.username}`}
              </p>
            </div>

            <div className="flex items-center gap-2">
              <Trophy className="w-4 h-4 text-yellow-500" />
              <span className="font-bold text-white">
                {player.wins}
              </span>
            </div>
          </motion.div>
        );
      })}
    </div>
  );
} 