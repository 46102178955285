import React, { useState, useEffect, useRef } from 'react';
import { ScrollArea } from "@/components/ui/scroll-area";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Send } from 'lucide-react';
import { useSession } from '@supabase/auth-helpers-react';
import { supabase } from '@/integrations/supabase/client';
import maskSensitiveInfo from '@/integrations/supabase/utils';
import { toast } from 'sonner';
import { cn } from '@/lib/utils';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { generateGradientAvatar } from '@/lib/generate-avatar';
import { Card } from "@/components/ui/card";
import { GameHistory } from './GameHistory';
import { useProfile } from '@/hooks/use-profile';

interface GameChatProps {
  gameId: string;
  moveHistory: Array<{
    player: number;
    from: string;
    to: string;
    type: string;
  }>;
  player1Name?: string;
  player2Name?: string;
  player1Id?: string;
  player2Id?: string;
}

interface ChatMessage {
  id: string;
  game_id: string;
  user_id: string | null;
  player_name: string | null;
  message: string;
  created_at: string;
  is_system_message?: boolean;
  system_event_type?: string;
  profile?: {
    username: string | null;
    display_name: string | null;
    avatar_url: string | null;
    equipped_skin_id: number | null;
    avatar_skins: {
      image_url: string | null;
    } | null;
  };
}

// Componente separado para o input
const ChatInput = React.memo(({ onSendMessage }: { onSendMessage: (text: string) => Promise<void> }) => {
  const [newMessage, setNewMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!newMessage.trim() || isLoading) return;

    setIsLoading(true);
    try {
      await onSendMessage(newMessage.trim());
      setNewMessage('');
    } catch (err) {
      console.error('Erro ao enviar mensagem:', err);
      toast.error('Erro ao enviar mensagem');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="flex gap-2">
      <Input
        value={newMessage}
        onChange={(e) => setNewMessage(e.target.value)}
        placeholder="Digite sua mensagem..."
        className="flex-1 bg-zinc-800 border-zinc-700"
        disabled={isLoading}
        name="message"
        id="message"
      />
      <Button 
        type="submit" 
        size="icon"
        disabled={!newMessage.trim() || isLoading}
        className="shrink-0"
      >
        <Send className="w-4 h-4" />
      </Button>
    </form>
  );
});

ChatInput.displayName = 'ChatInput';

export function GameChat({ 
  gameId, 
  moveHistory, 
  player1Name = "Jogador 1", 
  player2Name = "Jogador 2",
  player1Id,
  player2Id 
}: GameChatProps) {
  const session = useSession();
  const [messages, setMessages] = useState<ChatMessage[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const scrollRef = useRef<HTMLDivElement>(null);
  const { profile } = useProfile();
  const guestName = localStorage.getItem('guestName');

  const scrollToBottom = () => {
    if (scrollRef.current) {
      const scrollContainer = scrollRef.current.querySelector('[data-radix-scroll-area-viewport]');
      if (scrollContainer) {
        // Usar scrollIntoView no último elemento
        const lastMessage = scrollContainer.lastElementChild?.lastElementChild;
        lastMessage?.scrollIntoView({ behavior: 'smooth', block: 'end' });
        
        // Forçar scroll para garantir que chegue ao final
        setTimeout(() => {
          scrollContainer.scrollTop = scrollContainer.scrollHeight;
        }, 100);
      }
    }
  };

  // Adicionar useEffect para observar mudanças nas mensagens
  useEffect(() => {
    scrollToBottom();
  }, [messages]); // Scroll sempre que messages mudar

  useEffect(() => {
    const loadMessages = async () => {
      try {
        const { data, error } = await supabase
          .from('chat_messages')
          .select(`
            *,
            profile:user_id (
              username,
              display_name,
              avatar_url,
              equipped_skin_id,
              avatar_skins (
                image_url
              )
            )
          `)
          .eq('game_id', gameId)
          .order('created_at', { ascending: true });

        if (error) throw error;
        setMessages(data || []);
        setTimeout(scrollToBottom, 100);
      } catch (err) {
        console.error('Erro ao carregar mensagens:', maskSensitiveInfo(err));
      }
    };

    loadMessages();

    // Usar o mesmo canal do jogo para todas as atualizações
    const channel = supabase
      .channel(`game:${gameId}:chat`)
      .on(
        'postgres_changes',
        {
          event: '*',
          schema: 'public',
          table: 'chat_messages',
          filter: `game_id=eq.${gameId}`
        },
        async (payload) => {
          console.log('Mudança no chat detectada:', payload);
          
          // Recarregar todas as mensagens quando houver qualquer mudança
          const { data, error } = await supabase
            .from('chat_messages')
            .select(`
              *,
              profile:user_id (
                username,
                display_name,
                avatar_url,
                equipped_skin_id,
                avatar_skins (
                  image_url
                )
              )
            `)
            .eq('game_id', gameId)
            .order('created_at', { ascending: true });

          if (!error && data) {
            setMessages(data);
            setTimeout(scrollToBottom, 100);
          }
        }
      )
      .subscribe((status) => {
        console.log('Chat subscription status:', status);
      });

    return () => {
      channel.unsubscribe();
    };
  }, [gameId]);

  const handleSendMessage = async (text: string) => {
    if (!text.trim()) return;

    try {
      const messageData = {
        game_id: gameId,
        user_id: session?.user?.id || null,
        player_name: !session?.user ? guestName : null,
        message: text.trim()
      };

      const { error } = await supabase
        .from('chat_messages')
        .insert(messageData);

      if (error) throw error;
      
      // N��o precisamos recarregar as mensagens aqui
      // O canal de realtime vai cuidar disso
    } catch (err) {
      console.error('Erro ao enviar mensagem:', err);
      toast.error('Erro ao enviar mensagem');
    }
  };

  const getMessageSender = (message: ChatMessage) => {
    if (message.user_id) {
      // Usuário logado
      if (message.profile) {
        return message.profile.username ? 
          `@${message.profile.username}` : 
          message.profile.display_name || 'Jogador';
      }
      return 'Jogador';
    } else {
      // Jogador convidado
      return message.player_name || 'Convidado';
    }
  };

  const getMessageAvatar = (message: ChatMessage) => {
    if (message.user_id && message.profile) {
      if (message.profile.equipped_skin_id && message.profile.avatar_skins?.image_url) {
        return message.profile.avatar_skins.image_url;
      }
      if (message.profile.avatar_url) {
        return message.profile.avatar_url;
      }
    }
    return generateGradientAvatar(message.player_name || 'Convidado');
  };

  return (
    <div className="flex flex-col h-full">
      <ScrollArea ref={scrollRef} className="flex-1 p-4">
        <div className="space-y-4">
          {messages.map((message) => {
            // Se for uma mensagem do sistema, renderizar com estilo diferente
            if (message.is_system_message) {
              return (
                <div
                  key={message.id}
                  className="flex justify-center my-1"
                >
                  <div className={cn(
                    "px-2 py-1 rounded-full text-xs flex items-center gap-1.5",
                    "bg-zinc-800/30 text-zinc-400",
                    "border border-zinc-700/30",
                    message.system_event_type === 'player_joined' && "bg-green-900/10 border-green-500/10 text-green-400",
                    message.system_event_type === 'player_left' && "bg-red-900/10 border-red-500/10 text-red-400"
                  )}>
                    <div className={cn(
                      "w-1 h-1 rounded-full",
                      message.system_event_type === 'player_joined' && "bg-green-400",
                      message.system_event_type === 'player_left' && "bg-red-400",
                      !message.system_event_type && "bg-zinc-400"
                    )} />
                    {message.message}
                  </div>
                </div>
              );
            }

            // Renderização normal para mensagens de usuários
            const isMyMessage = session?.user ? 
              message.user_id === session.user.id : 
              message.player_name === guestName;

            const sender = getMessageSender(message);
            const avatar = getMessageAvatar(message);
            const messageTime = format(new Date(message.created_at), 'HH:mm');

            return (
              <div
                key={message.id}
                className={cn(
                  "flex items-start gap-2 group",
                  isMyMessage ? "flex-row-reverse" : "flex-row"
                )}
              >
                <Avatar className="w-8 h-8 shrink-0">
                  <AvatarImage src={avatar} />
                  <AvatarFallback>{sender[0]}</AvatarFallback>
                </Avatar>

                <div className={cn(
                  "flex flex-col",
                  isMyMessage ? "items-end" : "items-start"
                )}>
                  <div className={cn(
                    "px-3 py-2 rounded-2xl max-w-[280px] break-words",
                    isMyMessage 
                      ? "bg-blue-600 text-white rounded-br-none" 
                      : "bg-zinc-800 text-zinc-100 rounded-bl-none"
                  )}>
                    <div className="text-xs font-medium mb-1 opacity-90">
                      {sender}
                    </div>
                    <p className="text-sm">{message.message}</p>
                  </div>
                  <span className="text-xs text-zinc-500 px-2 opacity-0 group-hover:opacity-100 transition-opacity">
                    {messageTime}
                  </span>
                </div>
              </div>
            );
          })}
        </div>
      </ScrollArea>

      <div className="p-4 border-t border-zinc-800">
        <ChatInput onSendMessage={handleSendMessage} />
      </div>
    </div>
  );
} 