import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { BookOpen, ChevronRight, Crown, Circle, Target, Award, Brain, Shield } from 'lucide-react';
import { Card } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';

interface TutorialStep {
  title: string;
  content: string;
  image: string;
}

interface TutorialSection {
  title: string;
  icon: React.ReactNode;
  steps: TutorialStep[];
}

export default function Tutorial() {
  const [selectedSection, setSelectedSection] = useState('basics');

  const tutorialSections: Record<string, TutorialSection> = {
    basics: {
      title: "Conceitos Básicos",
      icon: <Crown className="w-5 h-5" />,
      steps: [
        {
          title: "O Tabuleiro",
          content: "O jogo de damas é jogado em um tabuleiro de 8x8 casas, alternadas entre claras e escuras. As peças só podem se mover nas casas escuras.",
          image: "/tutorial/board.png"
        },
        {
          title: "As Peças",
          content: "Cada jogador começa com 12 peças, posicionadas nas três primeiras fileiras do seu lado do tabuleiro. As peças se movem apenas nas diagonais, uma casa por vez.",
          image: "/tutorial/pieces.png"
        },
        {
          title: "Objetivo",
          content: "O objetivo é capturar todas as peças do adversário ou bloquear todas as suas peças, impedindo que elas se movam.",
          image: "/tutorial/objective.png"
        }
      ]
    },
    moves: {
      title: "Movimentos",
      icon: <Target className="w-5 h-5" />,
      steps: [
        {
          title: "Movimento Básico",
          content: "As peças se movem na diagonal, sempre para frente, uma casa por vez. Peças normais não podem mover-se para trás.",
          image: "/tutorial/basic-move.png"
        },
        {
          title: "Captura",
          content: "A captura é obrigatória e acontece quando uma peça pode pular sobre a peça do adversário, caindo em uma casa vazia. A peça capturada é removida do jogo.",
          image: "/tutorial/capture.png"
        },
        {
          title: "Capturas Múltiplas",
          content: "Se após uma captura for possível capturar outra peça, o jogador deve continuar capturando na mesma jogada.",
          image: "/tutorial/multiple-capture.png"
        }
      ]
    },
    advanced: {
      title: "Regras Avançadas",
      icon: <Brain className="w-5 h-5" />,
      steps: [
        {
          title: "Dama",
          content: "Quando uma peça atinge a última fileira do tabuleiro, ela é promovida à dama. A dama pode mover-se para frente e para trás nas diagonais, quantas casas quiser.",
          image: "/tutorial/king.png"
        },
        {
          title: "Captura com Dama",
          content: "A dama pode capturar peças em qualquer direção, desde que haja espaço vazio após a peça a ser capturada.",
          image: "/tutorial/king-capture.png"
        },
        {
          title: "Fim de Jogo",
          content: "O jogo termina quando um jogador captura todas as peças do adversário ou quando um jogador não pode mais realizar movimentos válidos.",
          image: "/tutorial/game-over.png"
        }
      ]
    },
    strategy: {
      title: "Estratégias",
      icon: <Shield className="w-5 h-5" />,
      steps: [
        {
          title: "Controle do Centro",
          content: "Mantenha suas peças próximas ao centro do tabuleiro. Isso oferece mais opções de movimento e melhor controle do jogo.",
          image: "/tutorial/center-control.png"
        },
        {
          title: "Proteção das Bordas",
          content: "Evite deixar peças isoladas nas bordas do tabuleiro. Peças nas bordas têm menos opções de movimento e são mais vulneráveis.",
          image: "/tutorial/edge-protection.png"
        },
        {
          title: "Formação Defensiva",
          content: "Mantenha suas peças conectadas em diagonal. Isso cria uma barreira defensiva e dificulta o avanço do adversário.",
          image: "/tutorial/defensive.png"
        }
      ]
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-[#000510] to-[#001233]">
      {/* Efeito de partículas */}
      <div className="absolute inset-0 overflow-hidden pointer-events-none">
        {[...Array(50)].map((_, i) => (
          <div
            key={i}
            className="absolute w-1 h-1 bg-blue-400 rounded-full animate-twinkle"
            style={{
              left: `${Math.random() * 100}%`,
              top: `${Math.random() * 100}%`,
              animationDelay: `${Math.random() * 3}s`,
              animationDuration: `${2 + Math.random() * 3}s`
            }}
          />
        ))}
      </div>

      {/* Conteúdo principal */}
      <div className="relative z-10 container mx-auto px-4 py-8">
        {/* Cabeçalho */}
        <div className="text-center mb-12">
          <motion.div
            initial={{ scale: 0.8, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            className="inline-block"
          >
            <BookOpen className="w-16 h-16 text-blue-500 mx-auto mb-4" />
          </motion.div>
          <h1 className="text-4xl font-bold text-white mb-2">
            Como Jogar Damas
          </h1>
          <p className="text-zinc-400">
            Aprenda as regras e estratégias do jogo de damas
          </p>
        </div>

        {/* Conteúdo do Tutorial */}
        <div className="max-w-4xl mx-auto">
          <Tabs
            value={selectedSection}
            onValueChange={setSelectedSection}
            className="w-full"
          >
            <TabsList className="bg-zinc-900/50 border border-zinc-800 w-full grid grid-cols-4 mb-6">
              {Object.entries(tutorialSections).map(([key, section]) => (
                <TabsTrigger
                  key={key}
                  value={key}
                  className="data-[state=active]:bg-blue-600"
                >
                  <div className="flex items-center gap-2">
                    {section.icon}
                    <span className="hidden sm:inline">{section.title}</span>
                  </div>
                </TabsTrigger>
              ))}
            </TabsList>

            {Object.entries(tutorialSections).map(([key, section]) => (
              <TabsContent key={key} value={key}>
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -20 }}
                  transition={{ duration: 0.2 }}
                  className="space-y-6"
                >
                  {section.steps.map((step, index) => (
                    <Card
                      key={index}
                      className="p-6 bg-zinc-900/50 border-zinc-800 hover:bg-zinc-900/80 transition-colors"
                    >
                      <div className="flex items-start gap-4">
                        <div className="flex-shrink-0 w-8 h-8 rounded-full bg-blue-600 flex items-center justify-center">
                          {index + 1}
                        </div>
                        <div className="flex-1">
                          <h3 className="text-xl font-semibold text-white mb-2">
                            {step.title}
                          </h3>
                          <p className="text-zinc-400 mb-4">
                            {step.content}
                          </p>
                          {step.image && (
                            <div className="relative aspect-video rounded-lg overflow-hidden bg-zinc-800">
                              <img
                                src={step.image}
                                alt={step.title}
                                className="w-full h-full object-cover"
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </Card>
                  ))}
                </motion.div>
              </TabsContent>
            ))}
          </Tabs>
        </div>
      </div>

      {/* Vinheta nas bordas */}
      <div 
        className="absolute inset-0 pointer-events-none"
        style={{
          boxShadow: 'inset 0 0 200px rgba(0,24,69,0.7)'
        }} 
      />
    </div>
  );
} 