import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Textarea } from "@/components/ui/textarea";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { useState } from "react";
import { useSupabase } from "@/hooks/use-supabase";
import { useToast } from "@/components/ui/use-toast";
import { TopicCategory, CATEGORY_LABELS } from "@/types/forum";
import { PenLine } from "lucide-react";

interface CreateTopicDialogProps {
  onSuccess?: () => void;
}

export function CreateTopicDialog({ onSuccess }: CreateTopicDialogProps) {
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [category, setCategory] = useState<TopicCategory>();
  const [loading, setLoading] = useState(false);
  const { supabase } = useSupabase();
  const { toast } = useToast();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!title || !content || !category) return;

    setLoading(true);
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error('Usuário não autenticado');

      const { error } = await supabase.from("forum_topics").insert({
        title,
        content,
        category,
        author_id: user.id
      });

      if (error) throw error;

      toast({
        title: "Tópico criado com sucesso!",
        description: "Seu tópico foi publicado na comunidade.",
      });

      setOpen(false);
      setTitle("");
      setContent("");
      setCategory(undefined);
      onSuccess?.();
    } catch (error) {
      toast({
        title: "Erro ao criar tópico",
        description: "Tente novamente mais tarde.",
        variant: "destructive",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button className="bg-blue-600 hover:bg-blue-700 text-white">
          <PenLine className="w-4 h-4 mr-2" />
          Criar Tópico
        </Button>
      </DialogTrigger>
      <DialogContent className="bg-gradient-to-b from-[#000510] to-[#001233] border-zinc-800 sm:max-w-[600px]">
        <form onSubmit={handleSubmit}>
          <DialogHeader>
            <DialogTitle className="text-xl font-bold text-white">Criar Novo Tópico</DialogTitle>
            <DialogDescription className="text-zinc-400">
              Compartilhe suas dúvidas, estratégias ou sugestões com a comunidade.
            </DialogDescription>
          </DialogHeader>
          <div className="grid gap-6 py-6">
            <div className="grid gap-2">
              <Label htmlFor="category" className="text-white">
                Categoria
              </Label>
              <Select value={category} onValueChange={(value) => setCategory(value as TopicCategory)}>
                <SelectTrigger id="category" className="bg-zinc-900/50 border-zinc-800 text-white">
                  <SelectValue placeholder="Selecione uma categoria" />
                </SelectTrigger>
                <SelectContent className="bg-zinc-900/95 border-zinc-800">
                  {Object.entries(CATEGORY_LABELS).map(([value, label]) => (
                    <SelectItem 
                      key={value} 
                      value={value}
                      className="text-white focus:bg-blue-600 focus:text-white"
                    >
                      {label}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
            <div className="grid gap-2">
              <Label htmlFor="title" className="text-white">
                Título
              </Label>
              <Input
                id="title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                className="bg-zinc-900/50 border-zinc-800 text-white placeholder:text-zinc-400"
                placeholder="Digite um título para seu tópico"
              />
            </div>
            <div className="grid gap-2">
              <Label htmlFor="content" className="text-white">
                Conteúdo
              </Label>
              <Textarea
                id="content"
                value={content}
                onChange={(e) => setContent(e.target.value)}
                className="bg-zinc-900/50 border-zinc-800 text-white placeholder:text-zinc-400 min-h-[150px]"
                placeholder="Descreva seu tópico em detalhes..."
              />
            </div>
          </div>
          <DialogFooter>
            <Button 
              variant="outline" 
              onClick={() => setOpen(false)}
              className="bg-zinc-900/50 border-zinc-800 text-white hover:text-white"
            >
              Cancelar
            </Button>
            <Button 
              type="submit" 
              disabled={loading || !title || !content || !category}
              className="bg-blue-600 hover:bg-blue-700 text-white"
            >
              {loading ? "Criando..." : "Criar Tópico"}
            </Button>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  );
} 