import { useNavigate } from 'react-router-dom';
import { MessageSquare, Eye, ThumbsUp } from 'lucide-react';
import { ForumTopic, CATEGORY_LABELS } from '@/types/forum';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { Badge } from '@/components/ui/badge';
import { generateGradientAvatar } from '@/lib/avatar';
import { formatDistanceToNow } from 'date-fns';
import { ptBR } from 'date-fns/locale';

interface TopicListProps {
  topics: ForumTopic[];
  loading: boolean;
}

export function TopicList({ topics, loading }: TopicListProps) {
  const navigate = useNavigate();

  if (loading) {
    return (
      <div className="space-y-4">
        {[...Array(5)].map((_, i) => (
          <div 
            key={i}
            className="bg-zinc-900/50 border border-white/5 rounded-lg p-4 animate-pulse"
          >
            <div className="h-6 w-2/3 bg-white/5 rounded mb-2" />
            <div className="h-4 w-1/3 bg-white/5 rounded" />
          </div>
        ))}
      </div>
    );
  }

  if (topics.length === 0) {
    return null;
  }

  return (
    <div className="space-y-4">
      {topics.map((topic) => {
        const avatarUrl = topic.author?.avatar_skin?.image_url ||
          topic.author?.avatar_url ||
          generateGradientAvatar(topic.author?.display_name || topic.author?.username || 'A');

        return (
          <div
            key={topic.id}
            className="bg-zinc-900/50 border border-white/5 rounded-lg p-4 hover:bg-zinc-900/70 transition-colors cursor-pointer"
            onClick={() => navigate(`/forum/topic/${topic.id}`)}
          >
            {/* Cabeçalho */}
            <div className="flex items-start justify-between gap-4 mb-3">
              <div className="flex-1">
                <div className="flex items-center gap-2 mb-2">
                  <Badge variant={topic.is_pinned ? 'default' : 'secondary'}>
                    {CATEGORY_LABELS[topic.category]}
                  </Badge>
                  {topic.is_pinned && (
                    <Badge variant="destructive">Fixado</Badge>
                  )}
                </div>
                <h3 className="text-lg font-medium text-white">
                  {topic.title}
                </h3>
              </div>
            </div>

            {/* Autor e Estatísticas */}
            <div className="flex items-center justify-between text-sm">
              <div className="flex items-center gap-2">
                <Avatar className="h-6 w-6">
                  <AvatarImage src={avatarUrl} />
                  <AvatarFallback>
                    {topic.author?.display_name?.charAt(0) || 
                     topic.author?.username?.charAt(0) || '?'}
                  </AvatarFallback>
                </Avatar>
                <span className="text-zinc-400">
                  {topic.author?.display_name || topic.author?.username}
                </span>
                <span className="text-zinc-600">•</span>
                <span className="text-zinc-400">
                  {formatDistanceToNow(new Date(topic.created_at), {
                    addSuffix: true,
                    locale: ptBR
                  })}
                </span>
              </div>
              <div className="flex items-center gap-4 text-zinc-400">
                <div className="flex items-center gap-1">
                  <Eye className="w-4 h-4" />
                  <span>{topic.views}</span>
                </div>
                <div className="flex items-center gap-1">
                  <MessageSquare className="w-4 h-4" />
                  <span>{topic.replies_count}</span>
                </div>
                <div className="flex items-center gap-1">
                  <ThumbsUp className="w-4 h-4" />
                  <span>{topic.reactions_count}</span>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
} 