import { useCallback, useEffect, useState } from 'react';
import { useSupabaseClient, useSession } from '@supabase/auth-helpers-react';
import { ForumTopic, ForumReply, ForumReaction, TopicCategory } from '@/types/forum';
import { toast } from 'sonner';

export function useForumTopics(category?: TopicCategory) {
  const supabase = useSupabaseClient();
  const [topics, setTopics] = useState<ForumTopic[]>([]);
  const [loading, setLoading] = useState(true);

  const fetchTopics = useCallback(async () => {
    try {
      setLoading(true);
      let query = supabase
        .from('forum_topics')
        .select(`
          *,
          author:profiles!forum_topics_author_id_fkey(
            id,
            username,
            display_name,
            avatar_url,
            equipped_skin_id,
            avatar_skin:avatar_skins!equipped_skin_id (
              image_url
            )
          ),
          replies_count:forum_replies(count),
          reactions_count:forum_reactions(count)
        `)
        .order('is_pinned', { ascending: false })
        .order('created_at', { ascending: false });

      if (category) {
        query = query.eq('category', category);
      }

      const { data, error } = await query;

      if (error) {
        console.error('Erro detalhado:', error);
        throw error;
      }

      if (!data) {
        console.warn('Nenhum dado retornado');
        setTopics([]);
        return;
      }

      setTopics(data.map(topic => ({
        ...topic,
        replies_count: topic.replies_count[0]?.count || 0,
        reactions_count: topic.reactions_count[0]?.count || 0
      })));
    } catch (error: any) {
      console.error('Erro ao carregar tópicos:', error);
      console.error('Detalhes do erro:', {
        message: error.message,
        details: error.details,
        hint: error.hint,
        code: error.code
      });
      toast.error('Erro ao carregar tópicos. Por favor, tente novamente.');
      setTopics([]);
    } finally {
      setLoading(false);
    }
  }, [supabase, category]);

  useEffect(() => {
    fetchTopics();
  }, [fetchTopics]);

  return { topics, loading, refetch: fetchTopics };
}

export function useForumTopic(topicId: string) {
  const supabase = useSupabaseClient();
  const session = useSession();
  const [topic, setTopic] = useState<ForumTopic | null>(null);
  const [replies, setReplies] = useState<ForumReply[]>([]);
  const [loading, setLoading] = useState(true);

  const fetchTopic = useCallback(async () => {
    try {
      setLoading(true);
      
      // Incrementar visualizações
      await supabase
        .rpc('increment_topic_views', { topic_id: topicId });

      // Carregar tópico
      const { data: topicData, error: topicError } = await supabase
        .from('forum_topics')
        .select(`
          *,
          author:profiles!forum_topics_author_id_fkey(
            id,
            username,
            display_name,
            avatar_url,
            equipped_skin_id,
            avatar_skin:avatar_skins!equipped_skin_id (
              image_url
            )
          ),
          reactions_count:forum_reactions(count)
        `)
        .eq('id', topicId)
        .single();

      if (topicError) throw topicError;

      // Carregar respostas
      const { data: repliesData, error: repliesError } = await supabase
        .from('forum_replies')
        .select(`
          *,
          author:profiles!forum_replies_author_id_fkey(
            id,
            username,
            display_name,
            avatar_url,
            equipped_skin_id,
            avatar_skin:avatar_skins!equipped_skin_id (
              image_url
            )
          ),
          reactions_count:forum_reactions(count)
        `)
        .eq('topic_id', topicId)
        .order('created_at', { ascending: true });

      if (repliesError) throw repliesError;

      // Organizar respostas em hierarquia
      const repliesMap = new Map();
      const topLevelReplies: ForumReply[] = [];

      repliesData.forEach(reply => {
        const formattedReply = {
          ...reply,
          reactions_count: reply.reactions_count[0]?.count || 0,
          nested_replies: []
        };
        repliesMap.set(reply.id, formattedReply);

        if (!reply.parent_id) {
          topLevelReplies.push(formattedReply);
        }
      });

      // Adicionar respostas aninhadas
      repliesData.forEach(reply => {
        if (reply.parent_id && repliesMap.has(reply.parent_id)) {
          const parentReply = repliesMap.get(reply.parent_id);
          parentReply.nested_replies.push(repliesMap.get(reply.id));
        }
      });

      setTopic({
        ...topicData,
        reactions_count: topicData.reactions_count[0]?.count || 0
      });
      
      setReplies(topLevelReplies);
    } catch (error) {
      console.error('Erro ao carregar tópico:', error);
      toast.error('Erro ao carregar tópico');
    } finally {
      setLoading(false);
    }
  }, [supabase, topicId]);

  useEffect(() => {
    fetchTopic();
  }, [fetchTopic]);

  const createReply = async ({ content, parent_id }: { content: string; parent_id?: string | null }) => {
    if (!session?.user?.id) {
      toast.error('Você precisa estar logado para responder');
      return;
    }

    try {
      const { error } = await supabase
        .from('forum_replies')
        .insert({
          topic_id: topicId,
          content,
          parent_id,
          author_id: session.user.id
        });

      if (error) throw error;
      
      await fetchTopic();
      toast.success('Resposta enviada com sucesso!');
    } catch (error: any) {
      console.error('Erro ao enviar resposta:', error);
      toast.error('Erro ao enviar resposta');
    }
  };

  const toggleReaction = async (reactionType: string, replyId?: string) => {
    if (!session?.user?.id) {
      toast.error('Você precisa estar logado para reagir');
      return;
    }

    try {
      const target = replyId ? { reply_id: replyId } : { topic_id: topicId };
      
      // Verificar se já existe reação
      const { data: existingReaction } = await supabase
        .from('forum_reactions')
        .select('id')
        .match({ ...target, user_id: session.user.id })
        .single();

      if (existingReaction) {
        // Remover reação existente
        await supabase
          .from('forum_reactions')
          .delete()
          .match({ id: existingReaction.id });
      } else {
        // Criar nova reação
        await supabase
          .from('forum_reactions')
          .insert({
            ...target,
            user_id: session.user.id,
            reaction_type: reactionType
          });
      }

      await fetchTopic();
    } catch (error: any) {
      console.error('Erro ao reagir:', error);
      toast.error('Erro ao reagir');
    }
  };

  return { 
    topic, 
    replies, 
    loading, 
    createReply,
    toggleReaction,
    refetch: fetchTopic 
  };
}

export function useCreateTopic() {
  const supabase = useSupabaseClient();
  const session = useSession();
  const [loading, setLoading] = useState(false);

  const createTopic = async (data: {
    title: string;
    content: string;
    category: TopicCategory;
  }) => {
    if (!session?.user?.id) {
      toast.error('Você precisa estar logado para criar um tópico');
      return false;
    }

    try {
      setLoading(true);
      const { error } = await supabase
        .from('forum_topics')
        .insert({
          ...data,
          author_id: session.user.id
        });

      if (error) throw error;

      toast.success('Tópico criado com sucesso!');
      return true;
    } catch (error: any) {
      console.error('Erro ao criar tópico:', error);
      toast.error('Erro ao criar tópico');
      return false;
    } finally {
      setLoading(false);
    }
  };

  return { createTopic, loading };
} 