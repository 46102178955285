import { useState, useEffect } from 'react';

const CONSENT_KEY = 'analytics_consent';

export function CookieConsent() {
  const [isVisible, setIsVisible] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    // Verifica se já existe uma preferência salva
    const savedConsent = localStorage.getItem(CONSENT_KEY);
    if (savedConsent === null) {
      // Pequeno delay para mostrar o banner após o carregamento da página
      const timer = setTimeout(() => setIsVisible(true), 1000);
      return () => clearTimeout(timer);
    } else {
      // Aplica a preferência salva
      window.updateAnalyticsConsent?.(savedConsent === 'true');
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem(CONSENT_KEY, 'true');
    window.updateAnalyticsConsent?.(true);
    setIsVisible(false);
  };

  const handleDecline = () => {
    localStorage.setItem(CONSENT_KEY, 'false');
    window.updateAnalyticsConsent?.(false);
    setIsVisible(false);
  };

  if (!isVisible) return null;

  return (
    <div className="fixed bottom-8 left-8 right-8 sm:left-auto sm:right-8 sm:bottom-8 sm:max-w-sm bg-zinc-900/95 backdrop-blur-sm border border-zinc-800 rounded-lg p-4 shadow-2xl z-50">
      <div className="space-y-4">
        <div className="flex items-start gap-3">
          <div className="shrink-0 w-10 h-10 rounded-full bg-blue-600/20 flex items-center justify-center">
            <svg 
              className="w-5 h-5 text-blue-500" 
              fill="none" 
              viewBox="0 0 24 24" 
              stroke="currentColor"
            >
              <path 
                strokeLinecap="round" 
                strokeLinejoin="round" 
                strokeWidth={2} 
                d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" 
              />
            </svg>
          </div>
          <div>
            <h3 className="font-medium text-white mb-1">Sua privacidade importa</h3>
            <div className="text-sm text-zinc-300 leading-relaxed">
              {isExpanded ? (
                <div className="space-y-2">
                  <div>
                    Utilizamos cookies para melhorar sua experiência e analisar o uso do site. 
                    Eles nos ajudam a entender como você interage com o jogo, permitindo-nos 
                    fazer melhorias contínuas.
                  </div>
                  <button 
                    onClick={() => setIsExpanded(false)}
                    className="text-blue-400 hover:text-blue-300"
                  >
                    Mostrar menos
                  </button>
                </div>
              ) : (
                <div className="space-y-2">
                  <div>Usamos cookies para melhorar sua experiência.</div>
                  <button 
                    onClick={() => setIsExpanded(true)}
                    className="text-blue-400 hover:text-blue-300"
                  >
                    Saiba mais
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="flex gap-2">
          <button
            onClick={handleDecline}
            className="flex-1 px-3 py-2 text-sm bg-zinc-800 hover:bg-zinc-700 text-zinc-300 hover:text-white rounded-md transition-colors duration-200"
          >
            Recusar
          </button>
          <button
            onClick={handleAccept}
            className="flex-1 px-3 py-2 text-sm bg-blue-600 hover:bg-blue-700 text-white rounded-md transition-colors duration-200 font-medium"
          >
            Aceitar
          </button>
        </div>
      </div>
    </div>
  );
}

// Adiciona a tipagem para a função global
declare global {
  interface Window {
    updateAnalyticsConsent?: (granted: boolean) => void;
  }
} 