import { useState, useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useSession } from '@supabase/auth-helpers-react';
import { Button } from '@/components/ui/button';
import { Textarea } from '@/components/ui/textarea';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { Badge } from '@/components/ui/badge';
import { Separator } from '@/components/ui/separator';
import { useForumTopic } from '@/hooks/use-forum';
import { CATEGORY_LABELS } from '@/types/forum';
import { generateGradientAvatar } from '@/lib/avatar';
import { formatDistanceToNow } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { ChevronLeft, Heart, MessageSquare, ThumbsUp, Award, Loader2 } from 'lucide-react';

// Componente separado para as partículas
function ParticlesEffect() {
  const particles = useMemo(() => 
    [...Array(50)].map(() => ({
      left: `${Math.random() * 100}%`,
      top: `${Math.random() * 100}%`,
      delay: `${Math.random() * 3}s`,
      duration: `${2 + Math.random() * 3}s`
    })), []
  );

  return (
    <div className="fixed inset-0 overflow-hidden pointer-events-none">
      {particles.map((particle, i) => (
        <div
          key={i}
          className="absolute w-1 h-1 bg-blue-400 rounded-full animate-twinkle"
          style={{
            left: particle.left,
            top: particle.top,
            animationDelay: particle.delay,
            animationDuration: particle.duration
          }}
        />
      ))}
    </div>
  );
}

export default function Topic() {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const session = useSession();
  const { topic, replies, loading, createReply, toggleReaction } = useForumTopic(id!);
  const [replyContent, setReplyContent] = useState('');
  const [replyingTo, setReplyingTo] = useState<string | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [expandedReplies, setExpandedReplies] = useState<Set<string>>(new Set());

  const toggleExpandReplies = (replyId: string) => {
    setExpandedReplies(prev => {
      const newSet = new Set(prev);
      if (newSet.has(replyId)) {
        newSet.delete(replyId);
      } else {
        newSet.add(replyId);
      }
      return newSet;
    });
  };

  const handleSubmitReply = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!replyContent.trim()) return;

    setIsSubmitting(true);
    try {
      // Encontrar a resposta principal (parent) para respostas aninhadas
      let parentId = replyingTo;
      if (replyingTo) {
        // Procura nas respostas aninhadas
        for (const reply of replies) {
          const nestedReply = reply.nested_replies?.find(nr => nr.id === replyingTo);
          if (nestedReply) {
            // Se encontrou nas respostas aninhadas, usa o ID da resposta principal
            parentId = reply.id;
            break;
          }
        }
      }

      await createReply({
        content: replyContent.trim(),
        parent_id: parentId
      });
      setReplyContent('');
      setReplyingTo(null);
    } catch (error) {
      console.error('Erro ao enviar resposta:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-gradient-to-b from-[#000510] to-[#001233]">
        <main className="container max-w-[900px] mx-auto px-4 py-8">
          <div className="flex justify-center items-center py-20">
            <Loader2 className="w-8 h-8 animate-spin text-blue-400" />
          </div>
        </main>
      </div>
    );
  }

  if (!topic) {
    return (
      <div className="min-h-screen bg-gradient-to-b from-[#000510] to-[#001233]">
        <main className="container max-w-[900px] mx-auto px-4 py-8">
          <div className="text-center py-20">
            <h2 className="text-2xl font-bold text-white mb-4">
              Tópico não encontrado
            </h2>
            <Button
              variant="outline"
              onClick={() => navigate('/forum')}
            >
              Voltar para o Fórum
            </Button>
          </div>
        </main>
      </div>
    );
  }

  const avatarUrl = topic.author?.avatar_skin?.image_url || 
    topic.author?.avatar_url ||
    generateGradientAvatar(topic.author?.display_name || topic.author?.username || 'A');

  return (
    <div className="min-h-screen bg-gradient-to-b from-[#000510] to-[#001233]">
      <ParticlesEffect />
      
      <main className="relative z-10 container max-w-[900px] mx-auto px-4 py-8">
        {/* Navegação */}
        <Button
          variant="outline"
          className="mb-6 bg-zinc-900/50 border-zinc-800 text-zinc-400 hover:bg-zinc-800 hover:text-white"
          onClick={() => navigate('/forum')}
        >
          <ChevronLeft className="w-4 h-4 mr-2" />
          Voltar para o Fórum
        </Button>

        {/* Tópico e Respostas */}
        <article className="bg-zinc-900/50 border border-zinc-800 rounded-lg p-6">
          {/* Cabeçalho do Tópico */}
          <div className="mb-8">
            <div className="flex items-center gap-2 mb-4">
              <Badge variant="secondary" className="bg-zinc-800 text-white">
                {CATEGORY_LABELS[topic.category]}
              </Badge>
              {topic.is_pinned && (
                <Badge variant="secondary" className="bg-blue-600 text-white">
                  Fixado
                </Badge>
              )}
            </div>

            <h1 className="text-2xl font-bold text-white mb-6">
              {topic.title}
            </h1>

            <div className="flex items-center gap-3 mb-6">
              <Avatar className="h-10 w-10">
                <AvatarImage src={avatarUrl} />
                <AvatarFallback>
                  {topic.author?.display_name?.charAt(0) || 
                   topic.author?.username?.charAt(0) || '?'}
                </AvatarFallback>
              </Avatar>
              <div>
                <div className="font-medium text-white">
                  {topic.author?.display_name || topic.author?.username}
                </div>
                <div className="text-sm text-zinc-400">
                  {formatDistanceToNow(new Date(topic.created_at), {
                    addSuffix: true,
                    locale: ptBR
                  })}
                </div>
              </div>
            </div>

            {/* Conteúdo do Tópico */}
            <div className="prose prose-invert max-w-none mb-6 text-zinc-200">
              {topic.content.split('\n').map((paragraph, index) => (
                <p key={index} className="text-zinc-200 break-words whitespace-pre-wrap">{paragraph}</p>
              ))}
            </div>

            <div className="flex items-center gap-4">
              <Button
                variant="ghost"
                size="sm"
                className="text-zinc-400 hover:bg-zinc-800 hover:text-white"
                onClick={() => toggleReaction('like')}
              >
                <ThumbsUp className="w-4 h-4 mr-1" />
                {topic.reactions_count || 0}
              </Button>
            </div>
          </div>

          {/* Separador */}
          <Separator className="my-8 bg-zinc-800" />

          {/* Formulário de Resposta Principal */}
          {session && !replyingTo && (
            <div className="mb-8">
              <h3 className="text-lg font-medium text-white mb-4">
                Sua Resposta
              </h3>
              <form onSubmit={handleSubmitReply}>
                <Textarea
                  value={replyContent}
                  onChange={(e) => setReplyContent(e.target.value)}
                  placeholder="Digite sua resposta..."
                  required
                  maxLength={10000}
                  rows={4}
                  className="mb-4 bg-zinc-900/50 border-zinc-800 text-white placeholder:text-zinc-400"
                />
                <Button 
                  type="submit" 
                  disabled={isSubmitting}
                  className="bg-blue-600 hover:bg-blue-700 text-white"
                >
                  {isSubmitting && (
                    <Loader2 className="w-4 h-4 mr-2 animate-spin" />
                  )}
                  Responder ao Tópico
                </Button>
              </form>
            </div>
          )}

          {/* Respostas */}
          <div className="space-y-6">
            <h2 className="text-xl font-bold text-white">
              Respostas ({replies.length})
            </h2>

            <div className="space-y-6">
              {replies
                .slice() // Cria uma cópia do array para não modificar o original
                .sort((a, b) => (b.reactions_count || 0) - (a.reactions_count || 0))
                .map((reply) => {
                  const replyAvatarUrl = reply.author?.avatar_skin?.image_url || 
                    reply.author?.avatar_url ||
                    generateGradientAvatar(reply.author?.display_name || reply.author?.username || 'A');

                  return (
                    <div
                      key={reply.id}
                      className="border-l-2 border-zinc-800 pl-4"
                    >
                      <div className="flex items-center gap-3 mb-2">
                        <Avatar className="h-8 w-8">
                          <AvatarImage src={replyAvatarUrl} />
                          <AvatarFallback>
                            {reply.author?.display_name?.charAt(0) || 
                             reply.author?.username?.charAt(0) || '?'}
                          </AvatarFallback>
                        </Avatar>
                        <div className="flex-1">
                          <div className="font-medium text-white">
                            {reply.author?.display_name || reply.author?.username}
                          </div>
                          <div className="text-sm text-zinc-400">
                            {formatDistanceToNow(new Date(reply.created_at), {
                              addSuffix: true,
                              locale: ptBR
                            })}
                          </div>
                        </div>
                        {reply.is_solution && (
                          <Badge variant="secondary" className="bg-green-600/20 text-green-400 border-green-800">
                            <Award className="w-3 h-3 mr-1" />
                            Solução
                          </Badge>
                        )}
                      </div>

                      {/* Conteúdo da Resposta */}
                      <div className="prose prose-invert max-w-none mb-3 text-zinc-200">
                        {reply.content.split('\n').map((paragraph, index) => (
                          <p key={index} className="text-zinc-200 break-words whitespace-pre-wrap">{paragraph}</p>
                        ))}
                      </div>

                      <div className="flex items-center gap-2">
                        <Button
                          variant="ghost"
                          size="sm"
                          className="text-zinc-400 hover:bg-zinc-800 hover:text-white"
                          onClick={() => toggleReaction('like', reply.id)}
                        >
                          <ThumbsUp className="w-4 h-4 mr-1" />
                          {reply.reactions_count || 0}
                        </Button>
                        {session && (
                          <Button
                            variant="ghost"
                            size="sm"
                            className="text-zinc-400 hover:bg-zinc-800 hover:text-white"
                            onClick={() => {
                              setReplyingTo(reply.id);
                              setReplyContent(`@${reply.author?.username} `);
                            }}
                          >
                            <MessageSquare className="w-4 h-4 mr-1" />
                            Responder
                          </Button>
                        )}
                      </div>

                      {/* Formulário de Resposta Aninhada */}
                      {session && replyingTo === reply.id && (
                        <div className="mt-4 pl-4">
                          <form onSubmit={handleSubmitReply}>
                            <div className="flex items-center gap-2 mb-2">
                              <h3 className="text-sm font-medium text-white">
                                Responder a {reply.author?.display_name || reply.author?.username}
                              </h3>
                              <Button
                                type="button"
                                variant="ghost"
                                size="sm"
                                className="text-zinc-400 hover:bg-zinc-800 hover:text-white"
                                onClick={() => {
                                  setReplyingTo(null);
                                  setReplyContent('');
                                }}
                              >
                                Cancelar
                              </Button>
                            </div>
                            <Textarea
                              value={replyContent}
                              onChange={(e) => setReplyContent(e.target.value)}
                              placeholder="Digite sua resposta..."
                              required
                              maxLength={10000}
                              rows={3}
                              className="mb-3 bg-zinc-900/50 border-zinc-800 text-white placeholder:text-zinc-400 text-sm"
                            />
                            <Button 
                              type="submit" 
                              size="sm"
                              disabled={isSubmitting}
                              className="bg-blue-600 hover:bg-blue-700 text-white text-sm"
                            >
                              {isSubmitting && (
                                <Loader2 className="w-3 h-3 mr-1 animate-spin" />
                              )}
                              Enviar Resposta
                            </Button>
                          </form>
                        </div>
                      )}

                      {/* Respostas Aninhadas */}
                      {reply.nested_replies && reply.nested_replies.length > 0 && (
                        <div className="mt-3 pl-4 space-y-3">
                          {(expandedReplies.has(reply.id) 
                            ? reply.nested_replies 
                            : reply.nested_replies.slice(0, 2)
                          ).map((nestedReply) => (
                            <div
                              key={nestedReply.id}
                              className="border-l border-zinc-800 pl-4 py-2"
                            >
                              <div className="flex items-center gap-3 mb-2">
                                <Avatar className="h-6 w-6">
                                  <AvatarImage 
                                    src={nestedReply.author?.avatar_skin?.image_url || 
                                      nestedReply.author?.avatar_url || 
                                      generateGradientAvatar(nestedReply.author?.display_name || nestedReply.author?.username || 'A')} 
                                  />
                                  <AvatarFallback>
                                    {nestedReply.author?.display_name?.charAt(0) || 
                                     nestedReply.author?.username?.charAt(0) || '?'}
                                  </AvatarFallback>
                                </Avatar>
                                <div>
                                  <div className="font-medium text-white text-sm">
                                    {nestedReply.author?.display_name || nestedReply.author?.username}
                                  </div>
                                  <div className="text-xs text-zinc-400">
                                    {formatDistanceToNow(new Date(nestedReply.created_at), {
                                      addSuffix: true,
                                      locale: ptBR
                                    })}
                                  </div>
                                </div>
                              </div>

                              <div className="prose prose-invert max-w-none mb-2 text-zinc-200 text-sm">
                                {nestedReply.content.split('\n').map((paragraph, index) => (
                                  <p key={index} className="text-zinc-200 break-words whitespace-pre-wrap">{paragraph}</p>
                                ))}
                              </div>

                              <div className="flex items-center gap-2">
                                <Button
                                  variant="ghost"
                                  size="sm"
                                  className="text-zinc-400 hover:bg-zinc-800 hover:text-white h-7 text-xs"
                                  onClick={() => toggleReaction('like', nestedReply.id)}
                                >
                                  <ThumbsUp className="w-3 h-3 mr-1" />
                                  {nestedReply.reactions_count || 0}
                                </Button>
                                {session && (
                                  <Button
                                    variant="ghost"
                                    size="sm"
                                    className="text-zinc-400 hover:bg-zinc-800 hover:text-white h-7 text-xs"
                                    onClick={() => {
                                      setReplyingTo(nestedReply.id);
                                      setReplyContent(`@${nestedReply.author?.username} `);
                                    }}
                                  >
                                    <MessageSquare className="w-3 h-3 mr-1" />
                                    Responder
                                  </Button>
                                )}
                              </div>

                              {/* Formulário de Resposta Aninhada */}
                              {session && replyingTo === nestedReply.id && (
                                <form onSubmit={handleSubmitReply} className="mt-4 pl-4 border-l border-zinc-800">
                                  <Textarea
                                    value={replyContent}
                                    onChange={(e) => setReplyContent(e.target.value)}
                                    placeholder="Digite sua resposta..."
                                    required
                                    maxLength={10000}
                                    rows={3}
                                    className="mb-3 bg-zinc-900/50 border-zinc-800 text-white placeholder:text-zinc-400 text-sm"
                                  />
                                  <div className="flex items-center gap-2">
                                    <Button 
                                      type="submit" 
                                      size="sm"
                                      disabled={isSubmitting}
                                      className="bg-blue-600 hover:bg-blue-700 text-white text-sm"
                                    >
                                      {isSubmitting && (
                                        <Loader2 className="w-3 h-3 mr-1 animate-spin" />
                                      )}
                                      Enviar
                                    </Button>
                                    <Button
                                      type="button"
                                      variant="ghost"
                                      size="sm"
                                      className="text-zinc-400 hover:bg-zinc-800 hover:text-white text-sm"
                                      onClick={() => {
                                        setReplyingTo(null);
                                        setReplyContent('');
                                      }}
                                    >
                                      Cancelar
                                    </Button>
                                  </div>
                                </form>
                              )}
                            </div>
                          ))}

                          {reply.nested_replies.length > 2 && (
                            <Button
                              variant="ghost"
                              size="sm"
                              className="text-zinc-400 hover:bg-zinc-800 hover:text-white ml-4 text-xs"
                              onClick={() => toggleExpandReplies(reply.id)}
                            >
                              {expandedReplies.has(reply.id)
                                ? "Mostrar menos respostas"
                                : `Ver todas as ${reply.nested_replies.length} respostas`}
                            </Button>
                          )}
                        </div>
                      )}
                    </div>
                  );
                })}
            </div>

            {/* Mensagem de Login */}
            {!session && (
              <div className="text-center py-8 bg-zinc-900/50 border border-zinc-800 rounded-lg mt-8">
                <MessageSquare className="w-12 h-12 text-zinc-600 mx-auto mb-4" />
                <h3 className="text-lg font-medium text-white mb-2">
                  Entre para participar
                </h3>
                <p className="text-zinc-400 mb-4">
                  Você precisa estar logado para responder a este tópico
                </p>
                <Button 
                  onClick={() => navigate('/login')}
                  className="bg-blue-600 hover:bg-blue-700 text-white"
                >
                  Fazer Login
                </Button>
              </div>
            )}
          </div>
        </article>
      </main>

      {/* Vinheta nas bordas */}
      <div 
        className="absolute inset-0 pointer-events-none"
        style={{
          boxShadow: 'inset 0 0 200px rgba(0,24,69,0.7)'
        }} 
      />
    </div>
  );
} 