export type TopicCategory = 'geral' | 'duvidas' | 'estrategias' | 'torneios' | 'bugs' | 'sugestoes';

export interface ForumTopic {
  id: string;
  title: string;
  content: string;
  category: TopicCategory;
  author_id: string;
  views: number;
  is_pinned: boolean;
  created_at: string;
  updated_at: string;
  author?: Profile;
  replies_count?: number;
  reactions_count?: number;
  my_reaction?: string;
}

export interface ForumReply {
  id: string;
  topic_id: string;
  content: string;
  author_id: string;
  parent_id?: string | null;
  is_solution: boolean;
  created_at: string;
  updated_at: string;
  author?: Profile;
  reactions_count?: number;
  my_reaction?: string;
  nested_replies?: ForumReply[];
}

export interface ForumReaction {
  id: string;
  user_id: string;
  topic_id?: string;
  reply_id?: string;
  reaction_type: 'like' | 'heart' | 'celebrate' | 'thinking';
  created_at: string;
}

export const CATEGORY_LABELS: Record<TopicCategory, string> = {
  geral: 'Geral',
  duvidas: 'Dúvidas',
  estrategias: 'Estratégias',
  torneios: 'Torneios',
  bugs: 'Bugs',
  sugestoes: 'Sugestões'
}; 